import { apiUrl, httpClient } from './http-client';
import { roles } from './constants'
import { allowedPermissions } from '../config/constants/permissions'
import { ROLES } from '../config/constants/roles'

export const isAllowedResource = (roleIdx, name) => {
  const role = Object.keys(ROLES).find(key => ROLES[key] === parseInt(roleIdx));
  return !!(allowedPermissions[ROLES[role]].includes('*') || allowedPermissions[ROLES[role]].includes(name));
};

export const authProvider = {
  login: ({username, password}) => {
    return httpClient(`${apiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
      }),
    }).then(({json}) => {
      localStorage.setItem('userId', json.userId);
      localStorage.setItem('permissions', json.role);
      localStorage.setItem('username', json.username)
      return Promise.resolve();
    })
  },
  logout: () => {
    localStorage.removeItem('userId');
    httpClient(`${apiUrl}/auth/logout`, {method: 'POST'});
    return Promise.resolve();
  },

  checkError: async ({status}) => {
    if (status === 401) {
      return Promise.reject();
    }

    return Promise.resolve();
  },

  checkAuth: () => {
    return localStorage.getItem('userId')
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  },

  getIdentity: () => {
    try {

      const role = localStorage.getItem('permissions')
      const username = localStorage.getItem('username')
      return Promise.resolve({fullName: `${username} | ${roles[role]}`})
    } catch (error) {
      return Promise.reject(error)
    }
  }
};
