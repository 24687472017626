import {
  Create,
  required,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { GoBackButton, ToolbarAlwaysOnSaveButton } from '../../components';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const BrandTagsCreate = props => {
  const { total } = useFetchEntities('brand');
 
  return (
    <>
      <GoBackButton path="/brand-iframe-tags" />
      <Create {...props} record={useProcessCopiedRecord('brand-iframe-tags') || null}>
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
        <ReferenceInput
          label="Brand"
          source="brand_id"
          reference="brand"
          sort={{field: "id", order: "ASC"}}
          perPage={total}
        >
          <AutocompleteInput
            className="search-input-md"
            optionText={(choice) => `${choice.id} - ${choice.name}`} 
          />
        </ReferenceInput>
        <ReferenceInput
          label="Affiliate"
          source="affiliate_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
          sort={{field: "affiliate_id", order: "ASC"}}
        >
          <AutocompleteInput
            className="search-input-md"
            optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Offer"
          source="offer_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
          sort={{field: "offer_id", order: "ASC"}}
        >
          <AutocompleteInput
            className="search-input-md"
            optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
          />
        </ReferenceInput>
          <TextInput source="tag_url" validate={[required()]} fullWidth />
        </SimpleForm>
      </Create>
    </>
)}
