import {
  useRecordContext,
  ReferenceField,
  TextField,
} from 'react-admin'
import { whiteSpaceNoWrap } from '../config/constants'

const ArrayEntityRender = ({ record, source, reference, targetSource }) => (
  record[source].map((item, index) => (
    <div key={index}>
      <span>{item}</span>
      <ReferenceField source={`${source}.${index}`} reference={reference}>
        {' - '} <TextField source={targetSource} />
      </ReferenceField>
    </div>
  ))
)

const SingleEntityRender = ({ record, source, reference, targetSource }) => (
  <>
    <span>{record[source] || ''}</span>
    {record[source] &&
      <ReferenceField source={source} reference={reference}>
        {' - '} <TextField source={targetSource} />
      </ReferenceField>
    }
  </>
)

export const EntityIdWithName = props => {
  let  { source, reference, targetSource, style } = props
  let record = useRecordContext(props)

  return (
    <span style={style || whiteSpaceNoWrap}>
      {Array.isArray(record[source]) 
        ? <ArrayEntityRender {...{record, source, reference, targetSource}} />
        : <SingleEntityRender {...{record, source, reference, targetSource}} />
      }
    </span>
  )
}
