import { useState, useEffect } from 'react';
import { httpClient, apiUrl } from '../config/http-client';
import { useNotify } from 'react-admin';

export const useFetchEntities = (endpoint) => {
   const [entities, setEntities] = useState([]);
   const [total, setTotal] = useState(0);
   const notify = useNotify();

   useEffect(() => {
      const fetchEntities = async () => {
         try {
            const initialResponse = await httpClient(`${apiUrl}/${endpoint}`, {
               method: 'GET',
            });
            const contentRange = initialResponse.headers.get('Content-Range');
            const totalEntities = parseInt(contentRange?.split('/')[1]) || 25;

            const allEntitiesResponse = await httpClient(`${apiUrl}/${endpoint}?range=%5B0%2C${totalEntities - 1}%5D`, {
               method: 'GET',
            });

            const fetchedEntities = allEntitiesResponse.json;

            setEntities(fetchedEntities);
            setTotal(totalEntities);
         } catch (error) {
            notify(`Failed to fetch ${endpoint}`, { type: 'error' });
         }
      };
   
      fetchEntities();
   }, [endpoint, notify]);

   return {
    entities,
    total
  };
};
