import {
   Create,
   SimpleForm,
   TextInput,
   SelectInput,
   number,
   ReferenceInput,
   AutocompleteInput,
   required,
} from 'react-admin'
import { ActionButton } from '../../components'
import { ToolbarAlwaysOnSaveButton } from '../../components/ToolbarAlwaysOnSaveButton'
import { PreApprovalSettings } from '../../config/constants'
import { setChoices } from '../../utils/setChoices'
import { validateDecimal } from '../../utils/helpers'
import {
   preApprovalSettingsCheckers,
   preApprovalSettingsStatuses,
   preApprovalSettingsCommission,
} from '../../config/constants';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const PreApprovalSettingsCreate = props => {
   return (
      <>
         <ActionButton type="back" />
         <Create {...props} record={useProcessCopiedRecord('pre-approval-settings') || null}>
            <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
               <ReferenceInput
                  label="Affiliate"
                  source="affiliate_id"
                  reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
                  sort={{field: "affiliate_id", order: "ASC"}}
               >
                  <AutocompleteInput
                     className="search-input-md"
                     optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
                  />
               </ReferenceInput>
               <ReferenceInput
                  label="Offer"
                  source="offer_id"
                  reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
                  sort={{field: "offer_id", order: "ASC"}}
               >
                  <AutocompleteInput
                     className="search-input-md"
                     optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
                  />
               </ReferenceInput>
               <SelectInput 
                  source="checker"
                  label="Checker"
                  choices={setChoices(preApprovalSettingsCheckers)}
               />
               <TextInput source="above_sale_amount" validate={validateDecimal} />
               <TextInput source="below_sale_amount" validate={validateDecimal} />
               <SelectInput source="result" choices={setChoices(PreApprovalSettings.decisionResult)}/>
               <SelectInput
                  source="status"
                  label="Status"
                  choices={setChoices(preApprovalSettingsStatuses)}
                  validate={[required()]}
               />
               <SelectInput
                  source="fallback_result"
                  choices={setChoices(PreApprovalSettings.decisionFallbackResult)}
               />
               <SelectInput
                  source="commission_structure"
                  choices={setChoices(preApprovalSettingsCommission)}
                  style={{ minWidth: '215px'}}
               />
               <TextInput source="untracked_period" validate={[number()]} />
            </SimpleForm>
         </Create>
      </>
   )
}
