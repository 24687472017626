import { Edit } from 'react-admin';
import {
  GoBackButton,
  AsideHistory,
  CustomEditToolbar,
} from '../../components';
import { AffiliateApiSettingsForm } from './AffiliateApiSettingsForm';

export const AffiliateApiSettingEdit = (props) => (
  <>
    <GoBackButton path="/affiliate-api-settings" />
    <Edit aside={<AsideHistory />} {...props}>
      <AffiliateApiSettingsForm
        mode="edit"
        formProps={{ toolbar: <CustomEditToolbar /> }}
      />
    </Edit>
  </>
);
