export const offerMetadataFields = [
  { source: 'offer_id', label: 'Offer ID', disabled: true, multiline: false },
  { source: 'offer_name', label: 'Offer', disabled: true, multiline: false },
  { source: 'advertiser_id', label: 'Advertiser ID', disabled: true, multiline: false },
  { source: 'advertiser_name', label: 'Advertiser', disabled: true, multiline: false },
  {
    source: 'channels',
    label: 'Channels',
    disabled: true,
    multiline: true,
  },
  {
    source: 'tags',
    label: 'Offer Tags',
    disabled: true,
    multiline: true,
  },
  {
    source: 'categories',
    label: 'Categories',
    disabled: true,
    multiline: true,
  },
  {
    source: 'conversion_types',
    label: 'Conversion Types',
    disabled: true,
    multiline: true,
  },
  {
    source: 'locations',
    label: 'Included Locations (countries)',
    disabled: true,
    multiline: true,
  },
];
