import React from 'react'

export const VersionHistoryPage = () => {
  return (
    <div className="rounded shadow m-5 p-5">
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h3>Version History</h3>
      </div>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v4.0.0 - <span className="version-date">28/10/24</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Tune Management, Offers</li>
          <ul>
            <li>Add Offers Table page with filters and search</li>
            <li>Add Offer Edit Page: Metadata tab and Partners table with filters</li>
            <li>Add the possibility to approve, unapprove, and block a partner for a specific offer</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v3.1.1 - <span className="version-date">17/09/24</span></h4>
        <h5>Fixes</h5>
        <ul>
          <li>Elastic Cron settings</li>
          <ul>
            <li>Fix the naming by adding the choices to Cron Name, Status, and Service Name</li> 
          </ul>
          <li>Display offer name on Pre-approval settings and Goals even if status is deleted</li>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v3.1.0 - <span className="version-date">22/05/24</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Cron settings</li>
          <ul>
            <li>Allow Admin users to edit current_id and last_id in crons</li>
            <li>Add validation to current_id and last_id to be positive integers</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v3.0.0 - <span className="version-date">07/05/24</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Brands:</li>
          <ul>
            <li>Add a react-admin column customization feature: edit/create fields, default off</li>
            <li>Add horizontal scroll for the feature described above</li>
          </ul>
          <li>Affiliate API Settings:</li>
          <ul>
            <li>Add New page Affiliate API Settings tab under Others menu</li>
            <li>Add columns: ID, Affiliate, API Key, Offers Limit, Brands Limit, Create Time, Update Time</li>
            <li>Add filters & search functionality</li>
            <li>Add Edit, Create and Copy row</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v2.4.0 - <span className="version-date">22/04/24</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Users</li>
          <ul>
            <li>Add a new User role: Brand Manager VA</li>
            <li>Disable delete functionality across the whole site for this role</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v2.35 - <span className="version-date">28/02/24</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Brands:</li>
          <ul>
            <li>Add react-admin column customization feature (without edit/create fields)</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v2.3 - <span className="version-date">21/02/24</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Brands:</li>
          <ul>
            <li>Search - add ID in Table view</li>
            <li>Add search to Connected Brand ID and Connect Brands Customers in Edit/Create</li>
            <li>Unique Conflict Cookies:</li>
            <ul>
              <li>Replace existing null values in unique_conflict_cookies to default value '[]'</li>
              <li>Make unique_conflict_cookies column non-null</li>
              <li>Prepare query to run</li>
            </ul>
          </ul>
          <li>Goal: Search - change Offer to Offer ID</li>
          <li>Notification emails:</li>
          <ul>
            <li>Add Search - Subject in Table view</li>
            <li>Add Filters - Plugin Status and Integration Type in Table view</li>
            <li>Add search to Brand field in Edit/Create</li>
          </ul>
          <li>Pre-approval Settings: Search - add Offer ID, Filters - add Checker</li>
          <li>Block-Hashed Emails: Search - add Brand ID and Hashed Email</li>
          <li>Tracking Settings: Search - rename Affiliate to Affiliate ID</li>
        </ul>
        <h5>Fixes</h5>
        <ul>
          <li>Brands: Plugin Status, not working with domain search</li>
          <li>Order Approval:</li>
          <ul>
            <li>Apply offer filter and write the same id in search field - shows ‘not results found’</li>
            <li>Remove Transaction ID and Customer Email</li>
          </ul>
          <li>Goal: Filters - Offers, not working with Postback URL Search</li>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v2.2 - <span className="version-date">14/02/24</span></h4>
        <h5>Fixes</h5>
        <ul>
          <li>Make Dashboard Lists Update/Create time to be the same as it is in the response from API, format: dd/MM/yyyy, HH:mm:ss</li>
          <li>Copy should not copy Create time & Update time for all tables</li>
          <li>Copy should not copy Check time for Brand, make Check time Null by default</li>
          <li>Logged out user not sent to log in page</li>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v2.1 - <span className="version-date">12/02/24</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Update Additional Domains UI in Brand settings</li>
          <ul>
            <li>Add additional label: Plugin Webhooks API Status: Active/Error</li>
            <li>Change label API Tracking Enabled to Rest API</li>
          </ul>
          <li>Domain tracking tab for Shopify</li>
          <ul>
            <li>Show Plugin Webhooks API Status: Active only if scopes criterion exists</li>
          </ul>
          <li>Domain tracking tab for BigCommerce</li>
          <ul>
            <li>Show Plugin Webhooks API Status: Active only if client_id & client_secret exist</li>
          </ul>
          <li>For Shopify&BigCommerce hide Rest API control button + hide additional domain section</li>
          <li>Block Hashed Emails</li>
          <ul>
            <li>Add search field: ID, Brand, Hashed Email, Update Time</li>
            <li>Aggregated view: remove hashed email value from rows, keep last update time</li>
          </ul>
          <li>Block Events, Aggregated view</li>
          <ul>
            <li>Make rows show values only for Count, Brand, & Update Time</li>
          </ul>
          <li>Pre-approval-Settings, Aggregated view</li>
          <ul>
            <li>Make rows show values only for Affiliate/Offer, Count & Update Time</li>
          </ul>
          <li>Order Approval, Aggregated view</li>
          <ul>
            <li>Make rows show values only for Affiliate/Brand, Count & Update Time</li>
          </ul>
          <li>Notification Emails, Aggregated view</li>
          <ul>
            <li>Make rows show values only for Count, Brand, & Update Time</li>
          </ul>
          <li>Notification Emails, Aggregated view</li>
          <ul>
            <li>Make rows show values only for Count, Offer, & Update Time</li>
            <li>Header Title fix when aggregated (Goals-aggregateds) to (Goals-Aggregated)</li>
          </ul>
          <li>Others/Notifications</li>
          <ul>
            <li>Add aggregate selector None/Brands</li>
            <li>Make rows show values only for Brands, & Count when in Brands aggregation</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v2.0 - <span className="version-date">06/02/24</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Update Additional Domains field in Brand settings</li>
          <ul>
            <li>Create a separate tab: Domain Tracking</li>
            <li>Add 2 boxes: Main domain API Tracking, Additional domain API Tracking</li>
            <li>Add validation to Additional Domain fields</li>
          </ul>
          <li>Add a confirmartion modal window to all Delete buttons</li>
        </ul>
        <h5>Fixes</h5>
        <ul>
          <li>Fix 2 bugs in Additional Domains fields in Brand settings when Copy the record:</li>
          <ul>
            <li>if change additional domains values and click save, the fields show the copied values, not the changed ones</li>
            <li>API Tracking Enabled switch (from Main box) can't be copied, always showed the default value</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.9 - <span className="version-date">30/01/24</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Update outdated packages of TrackCB Dashboard project:</li>
          <ul>
            <li>Fix audit - outdated packages and supported versions</li>
            <li>Set stable versions for packages to not allow them to autoupdate</li>
            <li>Add package-lock.json file (remove it from gitingnore)</li>
          </ul>
          <li>Hashed emails insert in bulk feature</li>
          <ul>
            <li>Add the button to Upload .csv files</li>
            <li>Add a logic to API to convert .csv files into queries and paste them to the database</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.85 - <span className="version-date">27/12/23</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Brands table:</li>
          <ul>
            <li>Add new field to Brands table: Additional Domain Tracking</li>
            <li>Field allows multiple values separated by comma</li>
            <li>Placement is on Details Tab below Site Secret field</li>
            <li>Add instructions/descriptive text below the field</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.8 - <span className="version-date">21/12/23</span></h4>
        <h5>Features</h5>
        <ul>
          <li>The following pages have the Aggregate by dropdown selector as found on pre-approval settings page</li>
          <ul>
            <li>Goals, default Aggregate by Offer, dropdown offers None selection</li>
            <li>Notification Emails, default Aggregate by Brand, dropdown offers None selection</li>
            <li>Order Approvals, default by brand, options for None, & Affiliate</li>
            <li>Block Events, default by brand, options for None</li>
            <li>Block hashed emails, default by brand, options for None</li>
          </ul>
        </ul>
        <h5>Fixes</h5>
        <ul>
          <li>Make Create Button work on all pages, while in aggregate view or not</li>
          <li>Notification Emails: some brand names are hidden in aggregate view</li>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.7 - <span className="version-date">06/12/23</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Add new tab Tune Postback</li>
          <ul>
            <li>Add new table Tune Postback Settings, filters, sorting, pagination</li>
            <li>Add possibility to Edit table row</li>
            <li>Add possibility to Create new row</li>
            <li>Fields: id, brand id, offer id, affiliate id, postback sub2, sub3, sub4, sub5, create time, update time</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.6 - <span className="version-date">14/11/23</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Global Changes</li>
          <ul>
            <li>Make aggregated table view for:</li>
            <ul>
              <li>Order Approvals</li>
              <li>Notification Emails</li>
              <li>Block Event</li>
              <li>Pre_approval Settings: ability to select 2 different type of views aggregation (affiliate, offer)</li>
            </ul>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">        
        <h4>TrackCB Dashboard v1.5 - <span className="version-date">18/10/23</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Global Changes</li>
          <ul>
            <li>Add/update validation of the following fields:</li>
            <ul>
              <li>Tracking Settings, Affiliate Priority Edit/Create</li>
              <ul>
                <li>Add validation to check if 'priority' field is a number</li>
              </ul>
              <li>Event Settings, Block Events Edit/Create</li>
              <ul>
                <li>Add validation to make 'type' field required</li>
              </ul>
              <li>Brand Settings, Brand Tags Edit</li>
              <ul>
                <li>Add validation to make 'tag_url' field required</li>
              </ul>
              <li>Brand Settings, Brand</li>
              <ul>
                <li>Brand Create: Add validation to make 'plugin_status' and 'conflict_type' fields required</li>
                <li>
                  Brand Edit: Add validation to make the following fields required:
                  'name','type','title','domain','home_url','site_key','site_secret','plugin_status','multiple_by','conflict_type'
                </li>
              </ul>
              <li>Brand Settings, Conflict Systems Edit/Create</li>
              <ul>
                <li>Add validation to make 'name' field required</li>
              </ul>
              <li>Event Settings, Coupon Events Edit/Create</li>
              <ul>
                <li>Add validation to make 'status' field required</li>
              </ul>
              <li>Others, Cron Settings Edit/Create</li>
              <ul>
                <li>Add validation to make 'cron_name' field required</li>
              </ul>
              <li>Others, Elastic Cron Settings Edit/Create</li>
              <ul>
                <li>Add validation to make 'cron_name', 'status' fields required</li>
              </ul>
              <li>Brand Settings, Goal Edit/Create</li>
              <ul>
                <li>Remove validation 'required' from 'event_type' field</li>
                <li>Add validation to make 'title', 'postback_url', 'parameters_map' fields required</li>
              </ul>
              <li>Brand Settings, Notification Emails Edit/Create</li>
              <ul>
                <li>Add validation to make 'Brand domain', 'Brand', 'Type', 'subject', 'template' fields required</li>
              </ul>
              <li>Event Settings, Pre Approval Settings Edit/Create</li>
              <ul>
                <li>Add validation to make 'status' field required</li>
              </ul>
              <li>Others, Users Edit/Create</li>
              <ul>
                <li>Add validation to make 'username' and 'password' fields required</li>
                <li>Remove validation 'required' from 'role' field</li>
              </ul>
            </ul>
          </ul>
          <li>Event Settings/Block Hashed Emails - Aggregate Table view</li>
          <li>Brand Settings/Goal - Add Refresh Active Tune Entities button to Header</li>
          <li>Add ID (database) to tables missing this column: Tracking Settings/Affiliate Priority, Event Settings/Block Events</li>
          <li>Make connect_brands_customers save null by default</li>
          <li>Make unique_conflict_cookies save [] by default</li>
          <li>Cron settings & Elastic cron settings: default table showing 50 rows and not 10</li>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.45 - <span className="version-date">04/10/23</span></h4>
        <h5>Fixes</h5>
        <ul>
          <li>Global Changes</li>
          <ul>
            <li>Not all Brands are displayed in filter field when scrolling because of pagination</li>
          </ul>
          <li>Event settings, Block Events</li>
          <ul>
            <li>Table is not showing some brand names</li>
          </ul>
          <li>Event settings, Coupon Events</li>
          <ul>
            <li>Code field should accept alphanumeric values</li>
          </ul>
          <li>Brand Settings, Goal</li>
          <ul>
            <li>Create/Edit Goal not saving NULL values. It saves empty array instead</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.4 - <span className="version-date">04/09/23</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Global changes throughout dashboard</li>
          <ul>
            <li>Make all field titles visible without values inside throughout system</li>
            <li>Select rows functionality - make Copy enabled for all tables</li>
            <li>All Brand, Affiliate, and Offer fields are dropdown menu of active options</li>
            <li>Rows per page, add option for 100, 200</li>
            <li>Update Tables to show Offer ID + Offer Name, Affiliate ID + Affiliate Name, Brand ID + Brand Name</li>
            <ul>
              <li>Affiliate Tracking Priority</li>
              <li>Block Event Settings</li>
              <li>Block Hashed Emails</li>
              <li>Brand Tags</li>
              <li>Coupon Event Settings</li>
              <li>Goals</li>
              <li>Notifications</li>
              <li>Pre Approval Events Decisions</li>
              <li>Pre Approval Events Settings</li>
            </ul>
            <li>Change namings</li>
            <ul>
              <li>Order Approvals List page to Order Approval</li>
              <li>Pre Approval Events Settings to Pre-approval Settings</li>
              <li>Pre Approval Events Decisions to Pre-approval Decisions</li>
              <li>Block Event Settings to Block Events</li>
              <li>Coupon Event Settings to Coupon Events</li>
              <li>Affiliate Tracking Priority to Affiliate Priority</li>
            </ul>
            <li>The default table size should be expanded relatively when the menu name changes below are completed</li>
            <li>Make Affiliate fields visible in Brand Settings/Brands Tags edit/create, Event settings/coupon Events, Tracking Settings/Affiliate Priority</li>
            <li>Copy should be enabled for all tables</li>
          </ul>
          <li>Brand settings, Goal</li>
          <ul>
            <li>Event Type Filter should have new selection - order_processing_cpa</li>
            <li>Edit/Create - add value order_processing_cpa</li>
          </ul>
          <li>Brand Tags - Edit/Create Brand Tags</li>
          <ul>
            <li>Brand, Affiliate, and Offer should be dropdown selections with search functionality</li>
          </ul>
          <li>Event settings</li>
          <ul>
            <li>Remove Create button from Block Hashed Emails</li>
            <li>Add Order Approval List table</li>
            <li>Order Approval List - Create/Edit</li>
            <ul>
              <li>Offer and Affiliate fields should be dropdown selection with search</li>
            </ul>
            <li>Pre Approval Settings</li>
            <ul>
              <li>Edit/Create: Affiliate ID and Offer ID fields should be dropdown selection with search display ID and Name</li>
              <li>Add 2 new fields (result & fallback_result) to the front end table and the fields should be included in edit/create mode</li>
              <li>Display columns in same order as DB</li>
            </ul>
            <li>Block Event Settings</li>
            <ul>
              <li>Edit/create - make Brand Field be dropdown search with Brand ID #/Brand Name</li>
            </ul>
            <li>Coupon Event Settings</li>
            <ul>
              <li>Edit/create: make Brand field, Affiliate, & Offer be dropdown/search</li>
              <li>Change header name to Coupon Event Settings</li>
            </ul>
            <li>Remove Pre-Approval Decisions menu/page</li>
          </ul>
          <li>Tracking Settings</li>
          <ul>
            <li>Affiliate Tracking Priority</li>
            <ul>
              <li>Edit/create: make Brand ID field, Affiliate fields be the dropdown/search</li>
              <li>Priority filter should have -1 option</li>
            </ul>
          </ul>
          <li>Others</li>
          <ul>
            <li>Notifications</li>
            <ul>
              <li>Display brand name next to Brand ID</li>
              <li>Add Filter for Brands, (with dropdown and search)</li>
            </ul>
            <li>Elastic Cron Settings</li>
            <ul>
              <li>Add Filters column to the table and add field to the edit/create</li>
            </ul>
          </ul>
        </ul>
        <h5>Fixes</h5>
        <ul>
          <li>Global Changes</li>
          <ul>
            <li>Fixed floating menus issue</li>
            <li>Make left sidebar fixed when table scrolls</li>
            <li>Make the height of table to fit the screen</li>
          </ul>
          <li>Brand settings, Brands</li>
          <ul>
            <li>Fields cut off when no text inside</li>
            <li>Create/Edit Brand</li>
            <ul>
              <li>Selecting tabs purple underline is not aligned with selected tab text</li>
            </ul>
            <li>Save not working when Copy row</li>
            <li>Values don't change when Copy row, change some values and click Save</li>
          </ul>
          <li>Brands, Conflict Systems Filter</li>
          <ul>
            <li>Values show #numbers and should show names</li>
          </ul>
          <li>Event settings, Events approval list</li>
          <ul>
            <li>Deleted</li>
          </ul>
          <li>Event settings, Block Event settings</li>
          <ul>
            <li>Added check if fileds customer, brand, transaction and visit are numbers</li>
          </ul>
          <li>Goals</li>
          <ul>
            <li>List: page does not fit in desktop mode</li>
            <li>Edit/Create: add dropdown and search to Offer and Affiliate fields (display # and name)</li>
          </ul>
          <li>Notification Emails</li>
          <ul>
            <li>Brand Sort, sorting 3 columns at once</li>
          </ul>
          <li>Events Settings Menu, Pre Approval Events Settings</li>
          <ul>
            <li>Table gets cut off in desktop</li>
          </ul>
          <li>Events Settings Menu, Order Approvals List page</li>
          <ul>
            <li>Table gets cut off in desktop</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.35 - <span className="version-date">02/08/23</span></h4>
        <h5>Features</h5>
        <ul>
          <li>Brand Settings Menu</li>
          <ul>
            <li>Add 2 fields below Title: Connected Brand ID and Connect Brands Customers</li>
          </ul>
          <li>Goals</li>
          <ul>
            <li>Add to front end table new column, Affiliate_id</li>
          </ul>
          <li>Others Settings Menu</li>
          <ul>
            <li>New table page link, Elastic Cron Settings</li>
            <ul>
              <li>Access to Admin only</li>
              <li>Possibility to Edit and Create records to Elastic Cron Settings</li>
            </ul>
            <li>Cron Settings</li>
            <ul>
              <li>Add Refresh Active Entities cron job that fetches active Offers and Affiliates from Tune</li>
              <li>Refresh Active Entities cron job updates active_offers and active_affiliates tables in the database</li>
              <li>Add button to refresh active entities manually</li>
            </ul>
          </ul>
          <li>Tracking Settings Menu</li>
          <ul>
            <li>Add new submenu page link, Shopify Sessions</li>
            <li>Possibility to Edit and Create records to Shopify Sessions</li>
            <li>Include search and filters</li>
          </ul>
          <li>CPA & Hybrid Tracking using Pre-Approval System</li>
          <ul>
            <li>Adding to table DB pre-approval-settings:</li>
            <li>Add new column Commission Structure (Selection of CPA & Hybrid)</li>
            <li>Add new column Untracked Period - measure days</li>
          </ul>
        </ul>
        <h5>Fixes</h5>
        <ul>
          <li>Events Settings Menu, Pre Approval Events Settings</li>
          <ul>
            <li>Fixed table not displaying in Dev or Prod</li>
            <li>Fixed ui of the table and Create/Edit pages</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.3</h4>
        <h5>Features</h5>
        <ul>
          <li>Updated React-admin library version from 3.18.1 to 4.9.0</li>
          <li>Added "Copy" feature which allows to create new records based on data in existing record. For tables:</li>
          <ul>
            <li>Brands</li>
            <li>Goals</li>
            <li>Pre Approval Event Settings</li>
            <li>Pre Approval Event Decisions</li>
          </ul>
          <li>Added theme toggle (dark and light themes)</li>
          <li>Added row style and toggle to change (color every second row)</li>
          <li>Added filters by brand name for 4 tables:</li>
          <ul>
            <li>Pre Approval Events Settings</li>
            <li>Pre Approval Events Decisions</li>
            <li>Block event settings</li>
            <li>Blocked hashed emails</li>
          </ul>
          <li>Added different tab names for dev and prod</li>
          <ul>
            <li>For dev: Dev AM TrackCB Dashboard</li>
            <li>For prod: AffsMedia TrackCB Dashboard</li>
          </ul>
        </ul>
        <h5>Fixes</h5>
        <ul>
          <li>Brands Page</li>
          <ul>
            <li>Fixed "Plugin info" field default value</li>
            <li>Fixed fields reset after generate Site Key</li>
          </ul>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.2</h4>
        <h5>Features</h5>
        <ul>
          <li>Brand Page</li>
          <ul>
            <li>Added description of fields on Edit Page</li>
            <li>Added button for copy "Site key" to clipboard on Edit page</li>
            <li>Default rows per page increased to 50</li>
            <li>Replaced # of a brand to brand name in title</li>
          </ul>
          <li>Goals Page</li>
          <ul>
            <li>Default rows per page increased to 25</li>
          </ul>

        </ul>
        <h5>Bug fixes</h5>
        <ul>
          <li>Plugin info field crash on Brand edit page</li>
        </ul>
      </p>
      <hr/>
      <p className="mb-5 mt-5">
        <h4>TrackCB Dashboard v1.1</h4>
        <h5>Features</h5>
        <ul>
          <li>Cron settings table</li>
          <li>Added UI for cron expression Edit/Create</li>
          <ul>
            <li>Added ability to control expression input</li>
          </ul>
          <li>Added cron expression descriptor on list page</li>
          <li>User settings</li>
          <ul>
            <li>Role code replaced by role name</li>
            <li>Deleted role code from Edit/Create</li>
          </ul>
          <li>Brand table</li>
          <ul>
            <li>Added button which generates Site key on Create page</li>
          </ul>
        </ul>
        <h5>Style</h5>
        <ul>
          <li>Added logo and favicon</li>
          <li>Added "Version History" header</li>
        </ul>
        <h5>Bugs</h5>
        <ul>
          <li>Fixed logout button</li>
        </ul>
      </p>
      <hr/>
      <p className="mb-3 mt-5">
        <h4>TrackCB Dashboard v1.0</h4>
        <h5>All Settings Tables</h5>
        <ul>
          <li>Create/Edit</li>
          <ul>
            <li>Back button should be displayed on all Create or Edit pages</li>
          </ul>
          <li>Max column length should be limited so full table can be seen</li>
          <ul>
            <li>Any values that contain list should not exceed 3</li>
            <ul>
              <li>Any value or list not displaying should be displayed via tooltip</li>
            </ul>
          </ul>
        </ul>
        <h5>Home page</h5>
        <ul>
          <li>Header</li>
          <ul>
            <li>Add Version number (1.0)</li>
            <ul>
              <li>Should link to an update page</li>
            </ul>
            <li>Display user name and role </li>
          </ul>
        </ul>
        <h5>Brand settings</h5>
        <ul>
          <li>Create/Edit</li>
          <ul>
            <li>Check time</li>
            <ul>
              <li>Add to history badge (in right side of page) </li>
            </ul>
            <li>Connected Brand ID</li>
            <ul>
              <li>Add to Details tab, also display Brand name </li>
              <ul>
                <li>Only edit brand ID</li>
              </ul>
            </ul>
            <li>Postback_sub3 [add to plugin tab]</li>
          </ul>
        </ul>
        <h5>Notification emails</h5>
        <ul>
          <li>Plugin status column, sort column was broken</li>
        </ul>
      </p>
    </div>
  )
}