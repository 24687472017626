import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNotify } from 'react-admin';
import {
  fetchTuneUpdateInfo,
  handleRefreshTuneEntities,
} from '../utils/fetchUtils/fetchTuneData';
import { TUNE_PROCESS_STATUS } from '../config/constants/tuneManagementSystem/commonConstants';
import { isWithinThirtyMinutes } from '../utils/dateUtils';

export const RefreshTuneEntitiesButton = ({
  style,
  label = 'Refresh Tune Entities',
}) => {
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const notify = useNotify();

  const updateStatus = async () => {
    setLoading(true);
    try {
      const { lastUpdateStatus, lastUpdateTime } = await fetchTuneUpdateInfo();

      if (lastUpdateStatus === TUNE_PROCESS_STATUS.IN_PROGRESS && isWithinThirtyMinutes(lastUpdateTime)) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    } catch (error) {
      console.error('Error fetching update status:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateStatus();
  }, []);

  const handleClick = async () => {
    setLoading(true);
    setDisableButton(true);
    try {
      const result = await handleRefreshTuneEntities();
      if (result.status === 'error') {
        notify(result.message, { type: 'error' });
        setDisableButton(false);
      } else {
        notify('Tune entities refresh started', { type: 'info' });
      }
    } catch (error) {
      notify('Failed to refresh tune entities', { type: 'error' });
      console.error('Error refreshing tune entities:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      style={style}
      variant="contained"
      color="secondary"
      onClick={handleClick}
      disabled={loading || disableButton}
    >
      {loading ? 'Updating...' : label}
    </Button>
  );
};
