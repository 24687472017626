import {
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

const AFF_TYPE_COUPONS = [
  "Aff Type Coupons-PPC-Bronze",
  "Aff Type Coupons-SEO-Silver"
];

export const OfferAffiliatesFilters = ({
  selectedFilter,
  setSelectedFilter,
  setSelectedAffiliate
}) => {
  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <ReferenceInput
        label="Search by Affiliate Name"
        source="affiliate_id"
        reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
        sort={{ field: 'affiliate_name', order: 'ASC' }}
      >
        <AutocompleteInput
          className="search-input-width"
          optionText={(choice) => choice.affiliate_name}
          onChange={setSelectedAffiliate}
        />
      </ReferenceInput>

      <FormControl>
        <InputLabel>Filter by Aff Type Coupons</InputLabel>
        <Select
          multiple
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
          style={{ width: '500px' }}
          renderValue={(selected) => selected.join(', ')}
        >
          {AFF_TYPE_COUPONS.map((coupon) => (
            <MenuItem key={coupon} value={coupon}>
              {coupon}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
