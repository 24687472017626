import {
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { setChoices } from '../setChoices';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const getAffPrioritiesFilters = (total) => [
  <TextInput
    source="search"
    label="ID, Brand ID, Affiliate ID"
    alwaysOn
    className="search-input-width"
  />,
  <SelectInput
    source="priority"
    label="Priority"
    choices={setChoices(['-1', '0', '1', '2'])}
  />,
  <ReferenceInput
    label="Brand"
    source="brand_id"
    reference="brand"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={total}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.name}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Affilate"
    source="affilate_id"
    reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
    sort={{ field: 'affiliate_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.affiliate_name}
    />
  </ReferenceInput>,
];
