import { Tooltip } from '@mui/material';

export const FormattedFieldWithTooltip = (items) => {
  if (!items || items.length === 0) return '';

  const itemsArray = typeof items === 'string' ? items.split(',') : items;

  const visibleItems = itemsArray.slice(0, 2).join(', ');
  const remainingItems = itemsArray.length > 2 ? `, ...` : '';
  const tooltipContent = itemsArray.join(', ');

  return (
    <Tooltip title={tooltipContent} arrow>
      <span>
        {visibleItems}
        {remainingItems}
      </span>
    </Tooltip>
  );
};
