import {
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { setChoices } from '../setChoices';
import { Brand } from '../../config/constants';

export const getBrandFilters = (total) => [
  <TextInput
    source="search"
    label="ID, Brand, Integration Type, Domain"
    alwaysOn
    className="search-input-width"
  />,
  <ReferenceInput
    label="Brand"
    source="name"
    reference="brand"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={total}
  >
    <AutocompleteInput
      label="Brand"
      className="search-input-width"
      optionValue="name"
      optionText={(choice) => choice.name}
    />
  </ReferenceInput>,
  <SelectInput
    source="type"
    label="Integration type"
    choices={setChoices(Brand.integrationTypes)}
  />,
  <SelectInput
    source="is_allow_track"
    label=" Tracking Status"
    choices={[
      { id: '1', name: 'Active' },
      { id: '0', name: 'Deactivate' },
    ]}
  />,
  <SelectInput
    source="plugin_status"
    choices={setChoices(Brand.pluginStatus)}
  />,
  <ReferenceInput
    label="Conflict Systems"
    source="conflict_systems_ids"
    reference="conflict-settings"
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" optionValue="name" fullWidth />
  </ReferenceInput>,
];
