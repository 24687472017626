import {
   Create,
   SimpleForm,
   TextInput,
   SelectInput,
   required,
   ReferenceArrayInput,
   AutocompleteArrayInput,
   ReferenceInput,
   AutocompleteInput,
} from 'react-admin'
import { setChoices } from '../../utils'
import { transformAffiliatesArray } from '../../utils/helpers'
import { Goals } from '../../config/constants'
import { ActionButton } from '../../components'
import { ToolbarAlwaysOnSaveButton } from '../../components/ToolbarAlwaysOnSaveButton'
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const GoalCreate = props => {
   return (
      <>
         <ActionButton type="back" />
         <Create
            {...props}
            record={useProcessCopiedRecord('goals') || null}
            transform={transformAffiliatesArray}
         >
            <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton localStorageValue='goals_list' />}>
               <SelectInput source="event_type" choices={setChoices(Goals.eventType)}/>
               <TextInput source="title" validate={[required()]}/>
               <TextInput source="postback_url" validate={[required()]} className="search-input-md"/>
               <TextInput source="parameters_map" validate={[required()]} initialValue="{}" className="search-input-md"/>
               <ReferenceInput
               label="Offer"
               source="offer_id"
               reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
               sort={{field: "offer_id", order: "ASC"}}
               >
                  <AutocompleteInput
                     className="search-input-md"
                     optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
                  />
               </ReferenceInput>
               <ReferenceArrayInput
                  label="Affiliate IDs"
                  source="affiliate_id"
                  reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
                  sort={{field: 'affiliate_id', order: 'ASC'}}
               >
                  <AutocompleteArrayInput
                     optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
                     optionValue="affiliate_id"
                     fullWidth
                  />
               </ReferenceArrayInput>
            </SimpleForm>
         </Create>
      </>
   )
}
