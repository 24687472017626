import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { GoBackButton, ToolbarAlwaysOnSaveButton } from '../../components';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';

export const ConflictSettingCreate = props => {
  const transform = data => {
    return {
      ...data,
      cookies: typeof data.cookies === 'string'
        ? data.cookies?.split(',').map(item => item.trim())
        : data.cookies || []
    }
  }

  return (
    <>
      <GoBackButton path="/conflict-settings"/>
      <Create {...props} transform={transform} record={useProcessCopiedRecord('conflict-settings') || null}>
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
          <TextInput source="name" validate={[required()]}/>
          <TextInput source="cookies" helperText="Please enter values separating them with coma" fullWidth/>
        </SimpleForm>
      </Create>
    </>
  )
}
