import { useRecordContext } from 'react-admin';

export const CustomArrayField = ({ source, renderArrayItem }) => {
  const record = useRecordContext();

  if (!record || !record[source]) {
    return null;
  }

  const content = renderArrayItem
    ? record[source].map(renderArrayItem).join(', ')
    : record[source].join(', ');

  return <div style={{ width: '300px', wordWrap: 'break-word', whiteSpace: 'normal' }}>{content}</div>;
};
