import {
  AutocompleteInput,
  SelectInput,
  ReferenceInput,
  TextInput,
} from 'react-admin';
import { setChoices } from '../setChoices';
import { Brand } from '../../config/constants';

export const getNotificationEmailsFilters = (total) => [
  <TextInput
    source="search"
    label="Subject, Brand ID, Update Date"
    className="search-input-width"
    alwaysOn
  />,
  <SelectInput
    source="sent_success"
    label="Sent success"
    choices={[
      { id: '1', name: 'True' },
      { id: '0', name: 'False' },
    ]}
  />,
  <SelectInput
    source="context.plugin_status"
    label="Plugin status"
    choices={setChoices(Brand.pluginStatus)}
  />,
  <ReferenceInput
    label="Brand"
    source="context.id"
    reference="brand"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={total}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.name}
      label="Brand"
    />
  </ReferenceInput>,
  <SelectInput
    source="context.type"
    label="Integration type"
    choices={setChoices(Brand.integrationTypes)}
  />,
];

export const getNotificationEmailsAggregatedFilters = (total) => [
  <TextInput
    source="search"
    label="Count, Brand ID, Update Date"
    className="search-input-width"
    alwaysOn
  />,
  <ReferenceInput
    label="Brand"
    source="context.id"
    reference="brand"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={total}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.name}
      label="Brand"
    />
  </ReferenceInput>,
];
