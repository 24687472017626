import { Toolbar, SaveButton, useRecordContext, useResourceContext } from 'react-admin';
import { DeleteConfirmationButton } from './DeleteConfirmationButton';
import { usePermissionCheck } from '../hooks/usePermissionCheck'
import { ROLES } from '../config/constants/roles'

export const CustomEditToolbar = ({ alwaysEnable, ...props }) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const isUserWithPermission = usePermissionCheck([
    ROLES.ADMIN,
    ROLES.BRANDS_MANAGER,
    ROLES.PUBLISHER_MANAGER,
    ROLES.MEDIA_BUYER
  ]);

  return (
    <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton alwaysEnable={alwaysEnable} />
      {record && record.id && isUserWithPermission && (
        <DeleteConfirmationButton resource={resource} selectedIds={[record.id]} />
      )}
    </Toolbar>
  );
};
