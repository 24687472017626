import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  number,
} from 'react-admin'
import { setChoices } from '../../utils'
import { AsideHistory, GoBackButton, CustomEditToolbar } from '../../components'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const CouponEventSettingsEdit = props => {
  const { total } = useFetchEntities('brand')

  return (
    <>
      <GoBackButton path="/coupon-settings"/>
      <Edit
        aside={<AsideHistory/>}
        title="Coupon Event Settings"
        {...props}
      >
        <SimpleForm toolbar={<CustomEditToolbar />}>
          <ReferenceInput
            label="Brand"
            source="brand_id"
            reference="brand"
            sort={{field: "id", order: "ASC"}}
            perPage={total}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.id} - ${choice.name}`}
              validate={[required()]}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Affiliate"
            source="affilate_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
            sort={{field: "affiliate_id", order: "ASC"}}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Offer"
            source="offer_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
            sort={{field: "offer_id", order: "ASC"}}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
            />
          </ReferenceInput>
          <SelectInput source="status" choices={setChoices(['active', 'deactive'])} validate={[required()]}/>
          <TextInput source="code" validate={[required()]}/>
          <TextInput source="multiple_by" validate={[required(), number()]}/>
        </SimpleForm>
      </Edit>
    </>
  )
}
