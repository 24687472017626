import { Typography, Chip } from '@mui/material';
import { isShopifyWithScopes, isBigCommerceWithCredentials } from '../../../utils/integrationUtils';

export const ApiTrackingStatusLabel = ({ pluginInfo, type }) => {
  let label = "Error";
  let backgroundColor = '#ffcccc';

  const setActiveLabelAndBackgroundColor = () => {
    label = "Active";
    backgroundColor = '#ccffcc';
  };

  if (isShopifyWithScopes(type, pluginInfo) || isBigCommerceWithCredentials(type, pluginInfo)) {
    setActiveLabelAndBackgroundColor();
  }

  return (
    <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
      Plugin Webhooks API Status:
      <Chip
        label={label}
        sx={{
          ml: 1,
          backgroundColor: backgroundColor,
          fontSize: '1rem'
        }}
      />
    </Typography>
  );
};
