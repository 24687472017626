import { useState, useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';
import { DEFAULT_PAGE_SIZE, SORT_FIELD, SORT_ORDER } from '../../../config/constants/tuneManagementSystem/offerAffiliatesConstants';
import { OfferAffiliatesFilters } from '../../../utils/filters/offerAffiliatesFilters';
import { getAffiliateColumns } from '../../../utils/columns/offerAffiliatesColumns';
import { OfferAffiliatesToolbar } from '../../../utils/toolbars/offerAffiliatesToolbar';
import { fetchPaginatedAffiliates } from '../../../utils/fetchUtils/fetchTuneData';
import { constructAffiliatesQueryParams, handleAffiliateAction } from '../../../utils/helpers/offerAffiliatesHelpers';

const AffiliatesTable = () => {
  const [affiliates, setAffiliates] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalAffiliates, setTotalAffiliates] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [sortModel, setSortModel] = useState([{ field: SORT_FIELD, sort: SORT_ORDER }]);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);

  const record = useRecordContext();
  const offerId = record?.id;

  useEffect(() => {
    fetchAffiliates();
  }, [page, pageSize, selectedFilter, sortModel, selectedAffiliate]);

  const fetchAffiliates = async () => {
    if (!offerId) return;
  
    setLoading(true);
    try {
      const queryParams = constructAffiliatesQueryParams({
        offerId,
        page,
        pageSize,
        selectedFilter,
        sortModel,
        selectedAffiliate,
      });
  
      const { affiliates, totalAffiliates } = await fetchPaginatedAffiliates({ queryParams });
  
      setAffiliates(affiliates);
      setTotalAffiliates(totalAffiliates);
    } catch (error) {
      console.error('Failed to fetch affiliates:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: '100%', flexDirection: 'column', position: 'relative' }}>
      <OfferAffiliatesFilters
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        setSelectedAffiliate={setSelectedAffiliate}
      />

      {selectedRows.length > 0 && (
        <OfferAffiliatesToolbar
          selectedRows={selectedRows}
          processing={processing}
          handleAffiliateAction={(actionType) => handleAffiliateAction({
            actionType,
            selectedRows,
            setProcessing,
            setSelectedRows,
            affiliates,
            offerId,
            fetchAffiliates,
          })}
        />
      )}

      <Paper style={{ width: '100%' }}>
        <DataGrid
          rows={affiliates}
          columns={getAffiliateColumns()}
          loading={loading}
          pagination
          paginationMode="server"
          sortingMode="server"
          pageSize={pageSize}
          rowCount={totalAffiliates}
          onPaginationModelChange={({ page, pageSize }) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          onRowSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
          rowSelectionModel={selectedRows}
          initialState={{ pagination: { paginationModel: { pageSize: DEFAULT_PAGE_SIZE } } }}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          sortModel={sortModel}
        />
      </Paper>
    </div>
  );
};

export default AffiliatesTable;
