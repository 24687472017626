import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

export const getHashedEmailsFilters = (total) => [
  <TextInput
    source="search"
    label="Count, Brand (ID, Name), Hashed Email, Update Date"
    className="search-input-width"
    alwaysOn
  />,
  <ReferenceInput
    label="Brand"
    source="brand_id"
    reference="brand"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={total}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.name}
    />
  </ReferenceInput>,
];

export const getHashedEmailsAggregatedFilters = (total) => [
  <TextInput
    source="search"
    label="Count, Brand (ID, Name), Update Date"
    className="search-input-width"
    alwaysOn
  />,
  <ReferenceInput
    label="Brand"
    source="brand_id"
    reference="brand"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={total}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.name}
    />
  </ReferenceInput>,
];
