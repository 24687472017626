import React, { useState } from 'react';
import { Button, useNotify, fetchUtils } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button as MuiButton,
} from '@mui/material';
import BrandSelector from './BrandSelector';
import FileUpload from './FileUpload';
import ConfirmationDialog from './ConfirmationDialog';
import { useFetchEntities } from '../hooks/useFetchEntities';
import { apiUrl } from '../config/http-client';
import { convertToBase64 } from '../utils/helpers';

export const UploadButton = () => {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [numberOfRows, setNumberOfRows] = useState(null);
  const [numberOfDuplicates, setNumberOfDuplicates] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const notify = useNotify();

  const { entities: brands } = useFetchEntities('brand');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedBrand(null);
    setFile(null);
    setError('');
    setOpen(false);
    setConfirmationOpen(false);
  };

  const handleConfirmationOpen = async () => {
    setLoading(true);
    setError('');

    try {
      const fileBase64 = await convertToBase64(file);
      const response = await fetchUtils.fetchJson(
        `${apiUrl}/block-hashed-emails/process-csv`,
        {
          method: 'POST',
          body: JSON.stringify({
            file: fileBase64,
            action: 'count',
            brandId: selectedBrand?.id,
          }),
        }
      );

      const data = response.json;
      if (data.hasOwnProperty('rowCount')) {
        setNumberOfRows(data.rowCount);
        setNumberOfDuplicates(data.duplicatesCount);
        setConfirmationOpen(true);
      } else if (data.hasOwnProperty('message')) {
        setError(data.message);
      }
    } catch (error) {
      console.error('Error:', error, error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSendToDatabase = async () => {
    setLoading(true);
    setError('');

    try {
      const fileBase64 = await convertToBase64(file);
      const response = await fetchUtils.fetchJson(
        `${apiUrl}/block-hashed-emails/process-csv`,
        {
          method: 'POST',
          body: JSON.stringify({
            file: fileBase64,
            action: 'insert',
            brandId: selectedBrand?.id,
          }),
        }
      );

      const data = response.json;
      if (data.hasOwnProperty('message')) {
        notify(data.message, { type: 'success' });
        handleClose();
      } else {
        setError('Error during record insertion.');
      }
    } catch (error) {
      setError('Failed to insert records into database');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button label="Upload .csv" onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Upload .csv</DialogTitle>
        <DialogContent sx={{ height: '210px' }}>
          <BrandSelector
            brands={brands}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
          />
          <FileUpload
            file={file}
            setFile={setFile}
            error={error}
            setError={setError}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton
            onClick={handleConfirmationOpen}
            color="primary"
            disabled={!selectedBrand || !file || loading}
          >
            Send to database
          </MuiButton>
          <MuiButton onClick={handleClose} color="primary">
            Cancel
          </MuiButton>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog 
        open={confirmationOpen}
        handleConfirm={handleSendToDatabase}
        handleCancel={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setConfirmationOpen(false);
          }
        }}
        numberOfRows={numberOfRows}
        numberOfDuplicates={numberOfDuplicates}
        selectedBrand={selectedBrand}
        loading={loading}
      />
    </div>
  );
};
