import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SimpleForm,
  required
} from 'react-admin';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const AffiliateApiSettingsForm = ({ mode, ...props }) => {
  const { total } = useFetchEntities('brand');

  return (
    <SimpleForm {...props.formProps}>
      {mode === 'edit' && <TextInput source="id" disabled />}
      <ReferenceInput
        label="Affiliate"
        source="affiliate_id"
        reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
        sort={{ field: 'affiliate_id', order: 'ASC' }}
      >
        <AutocompleteInput
          className="search-input-md"
          optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
          validate={[required()]}
        />
      </ReferenceInput>
      <TextInput source="api_key" className="search-input-md" />
      <ReferenceArrayInput
        label="Offers Limit"
        source="offers_limit"
        reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
        sort={{ field: 'offer_id', order: 'ASC' }}
      >
        <AutocompleteArrayInput
          optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
          style={{ minWidth: "680px" }}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Brands Limit"
        source="brands_limit"
        reference="brand"
        sort={{ field: 'id', order: 'ASC' }}
        perPage={total}
      >
        <AutocompleteArrayInput
          optionText={(choice) => `${choice.id} - ${choice.name}`}
          style={{ minWidth: "680px" }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};
