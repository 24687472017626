import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { AsideHistory, GoBackButton, CustomEditToolbar } from '../../components'

export const ConflictSettingEdit = props => {
  const transform = data => {
    return {
      ...data,
      cookies: typeof data.cookies === 'string'
        ? data.cookies?.split(',').map(item => item.trim())
        : data.cookies || []
    }
  }

  return (
    <>
      <GoBackButton path="/conflict-settings"/>
      <Edit {...props} aside={<AsideHistory/>} transform={transform}>
        <SimpleForm toolbar={<CustomEditToolbar />}>
          <TextInput source="name" validate={[required()]}/>
          <TextInput source="cookies" helperText="Please enter values separating them with coma" fullWidth/>
        </SimpleForm>
      </Edit>
    </>
  )
}
