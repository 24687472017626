import * as React from 'react'
import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin'
import { setChoices } from '../../utils'
import { transformAffiliatesArray } from '../../utils/helpers'
import { Goals } from '../../config/constants'
import { GoBackButton, AsideHistory, CustomEditToolbar } from '../../components'
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const GoalEdit = (props) => {
   return (
      <>
         <GoBackButton path="/goals" />
         <Edit aside={<AsideHistory />} {...props} transform={transformAffiliatesArray}>
         <SimpleForm toolbar={<CustomEditToolbar />}>
            <SelectInput
               source="event_type"
               choices={setChoices(Goals.eventType)}
            />
            <TextInput source="title" validate={[required()]}/>
            <TextInput source="postback_url" validate={[required()]} fullWidth />
            <TextInput source="parameters_map" validate={[required()]} fullWidth />
            <ReferenceInput
               label="Offer"
               source="offer_id"
               reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
               sort={{field: "offer_id", order: "ASC"}}
            >
               <AutocompleteInput
                  className="search-input-md"
                  optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
               />
            </ReferenceInput>
            <ReferenceArrayInput
               label="Affiliate IDs"
               source="affiliate_id"
               reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
               sort={{field: 'affiliate_id', order: 'ASC'}}
            >
               <AutocompleteArrayInput
                  optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
                  optionValue="affiliate_id"
                  fullWidth
               />
            </ReferenceArrayInput>
         </SimpleForm>
         </Edit>
      </>
  );
};
