import { Typography, Chip } from '@mui/material';
import { convertCronStatusToString } from '../../../utils/brandUtils';

export const CronStatusLabel = ({ cronStatus }) => {
  const formattedCronStatus = typeof cronStatus === 'string' ? cronStatus : convertCronStatusToString(cronStatus);

  return (
    <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
      Plugin Webhooks API Status:
      <Chip
        label={formattedCronStatus === 'error' ? 'Error' : 'Active'}
        sx={{
          ml: 1,
          backgroundColor: formattedCronStatus === 'error' ? '#ffcccc' : '#ccffcc',
          fontSize: '1rem'
        }}
      />
    </Typography>
  );
};
