export const REQUESTS_ROUTES = {
  GET_ACTIVE_AFFILIATES: 'active-affiliates/get-active-affiliates',
  GET_ACTIVE_OFFERS: 'offers/get-active-offers',
  GET_ALL_OFFERS: 'offers/get-all-offers',
  REFRESH_TUNE_ENTITIES: 'tune-entities/refresh-entities',
  GET_OFFER_LOCATIONS: 'tune-entities/get-offer-locations',
  GET_ADVERTISERS: 'tune-entities/get-advertisers',
  GET_PAGINATED_ACTIVE_AFFILIATES: 'active-affiliates',
  GET_OFFER_TAGS: 'tune-entities/get-offer-tags',
  GET_CHANNELS: 'tune-entities/get-channels',
  GET_CONVERSION_TYPES: 'tune-entities/get-conversion-types',
  LAST_UPDATE_TUNE_TIME: 'tune-entities/last-update-tune-time',
  GET_LOCATIONS: 'tune-entities/get-locations',
}
