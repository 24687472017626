import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
   List,
   Datagrid,
   TextField,
   NumberField,
} from 'react-admin'
import { RowsPerPage, DateInOneRow, EntityIdWithName } from '../../components'
import { aggregatedTablesPaths } from '../../config/constants'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { useRowStyle } from '../../hooks/useRowStyle'
import { useRedirectIfNoFilter } from '../../hooks/useRedirectIfNoFilter'
import { BulkActionCopy } from '../../components/BulkActionCopy'
import { compareAggregateValue } from '../../utils/aggregateTablesUtils'
import { preApprovalFilters } from '../../utils/filters/preapprovalSettingsFilters';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const PreApprovalSettingsList = props => {
   const rowClassName = useRowStyle()
   const location = useLocation()
   const aggregateValue = localStorage.getItem('aggregate') || '';
   
   const redirectLink = aggregatedTablesPaths['pre-approval-settings'][aggregateValue] || 'pre-approval-settings';
   const shouldRedirect = compareAggregateValue('');

   useRedirectIfNoFilter({
      filterKey: aggregateValue,
      redirectLink: redirectLink,
      sourceKey: 'RaStore.pre-approval-settings.listParams',
      targetKey: `RaStore.${redirectLink}.listParams`,
      shouldRedirect
   });

   useEffect(() => {
      const params = new URLSearchParams(location.search)
      const entityId = params.get('filter')

      if (!entityId) {
         localStorage.setItem('aggregate', '');
      }
   }, [location.search])

   return (
      <List {...props}
            pagination={<RowsPerPage />}
            filters={preApprovalFilters}
            className="list-admin-wrapper"
            actions={<ToolBarRowStyleToggle tableName='pre-approval-settings' />}
      >
         <Datagrid rowClick="edit"
                   className={rowClassName}
                   bulkActionButtons={<BulkActionCopy />}
         >
            <TextField source="id" />
            <EntityIdWithName
               source="affiliate_id"
               reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
               targetSource="affiliate_name"
               style={{
                  maxWidth: '150px',
                  wordWrap: 'break-word'
               }}
            />
            <EntityIdWithName
               source="offer_id"
               reference={REQUESTS_ROUTES.GET_ALL_OFFERS}
               targetSource="offer_name"
            />
            <TextField source="checker" />
            <NumberField source="above_sale_amount" />
            <NumberField source="below_sale_amount" />
            <TextField source="result" />
            <TextField source="status" />
            <TextField source="fallback_result" />
            <TextField source="commission_structure" />
            <TextField source="untracked_period" />
            <DateInOneRow
               source="update_time"
               label="Update time"
               showTime={true} 
               style={{
                  maxWidth: '150px',
                  wordWrap: 'break-word'
               }}
            />
         </Datagrid>
      </List>
   )
}
