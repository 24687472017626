import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  number,
} from 'react-admin';
import { setChoices } from '../../utils';
import { GoBackButton, ToolbarAlwaysOnSaveButton } from '../../components';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const CouponEventSettingsCreate = props => {
  const { total } = useFetchEntities('brand');

  return (
    <>
      <GoBackButton path="/coupon-settings"/>
      <Create
        {...props}
        record={useProcessCopiedRecord('coupon-settings') || null}
        title="Coupon Event Settings"
      >
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
          <ReferenceInput
            label="Brand"
            source="brand_id"
            reference="brand"
            sort={{field: "id", order: "ASC"}}
            perPage={total}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.id} - ${choice.name}`}
              validate={[required()]}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Affiliate"
            source="affilate_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
            sort={{field: "affiliate_id", order: "ASC"}}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Offer"
            source="offer_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
            sort={{field: "offer_id", order: "ASC"}}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
            />
          </ReferenceInput>
          <SelectInput source="status" choices={setChoices(['active', 'deactive'])} validate={[required()]}/>
          <TextInput source="code" validate={[required()]}/>
          <TextInput source="multiple_by" validate={[required(), number()]} label="Multiple by"/>
        </SimpleForm>
      </Create>
    </>
  )
}
