import {
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  TextInput,
} from 'react-admin';
import { Card, Typography, Button } from '@mui/material';
import { CustomBooleanInput } from './CustomBooleanInput';
import { CronStatusLabel } from './CronStatusLabel';
import {
  validateAdditionalUrl,
  extractIndexFromClassName,
  handleRemoveDomainTrackingEntry,
} from '../../../utils/brandUtils';

export const AdditionalDomainTrackingCard = ({
  source,
  mainDomain,
  onDomainChange,
  onCronStatusChange,
  getDomainValue,
  setAdditionalDomainTracking,
  setCopiedRecord,
}) => {
  const handleRemove = (e) => {
    const indexToRemove = extractIndexFromClassName(e.target.className);
    if (indexToRemove !== -1) {
      handleRemoveDomainTrackingEntry({ indexToRemove, setAdditionalDomainTracking, setCopiedRecord });
    }
  };

  return (
    <Card className="p-4 mt-3">
      <Typography variant="h6" sx={{ width: '460px' }}>
        Additional domain API tracking
      </Typography>
      <div className="row pt-3 px-2">
        <ArrayInput source={source} label="">
          <SimpleFormIterator
            removeButton={<Button onClick={(e) => handleRemove(e)}>x</Button>}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, ...rest }) => (
                <div className="array-input-item">
                  <TextInput
                    source={getSource('additional_url')}
                    label="Additional Domain"
                    fullWidth
                    validate={(value) => validateAdditionalUrl({ value, domain: mainDomain })}
                    value={getDomainValue && (scopedFormData?.additional_url || '')}
                    onBlur={onDomainChange
                      ? (event) => onDomainChange(getSource('additional_url'), event.target.value)
                      : undefined
                    }
                  />
                  <CronStatusLabel cronStatus={scopedFormData?.cron_status} />
                  <CustomBooleanInput
                    source={getSource('cron_status')}
                    record={scopedFormData}
                    onChange={onCronStatusChange
                      ? (newValue) => onCronStatusChange(getSource('cron_status'), newValue)
                      : undefined
                    }
                    {...rest}
                  />
                </div>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </div>
    </Card>
  );
};
