import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { tableMenuItems } from '../config/constants';
import { useAggregateValue, handleAggregateChange } from '../utils/aggregateTablesUtils';

export const AggregateBySelect = ({ tableName }) => {
  const navigate = useNavigate();
  const aggregateValue = useAggregateValue(tableName);
  const onAggregateChange = handleAggregateChange(navigate, tableName);

  return (
    <FormControl
      variant="outlined"
      size="small"
      style={{ margin: '0 10px', width: '145px' }}
    >
      <InputLabel htmlFor="aggregate-by">Aggregate by</InputLabel>
      <Select
        label="Aggregate by"
        value={aggregateValue}
        onChange={onAggregateChange}
        id="aggregate-by"
      >
        {tableMenuItems[tableName]?.options?.map(item => (
          <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
