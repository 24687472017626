import {
  Typography,
  Tooltip,
  IconButton,
  Toolbar as MuiToolbar,
} from '@mui/material';

export const OfferAffiliatesToolbar = ({
  selectedRows,
  processing,
  handleAffiliateAction,
}) => {
  return (
    <MuiToolbar
      className="toolbar-style"
      style={{ justifyContent: 'space-between' }}
    >
      <Typography variant="subtitle1" component="div">
        {selectedRows.length} selected
      </Typography>
      <div>
        <Tooltip title="Approve">
          <span>
            <IconButton
              aria-label="approve"
              onClick={() => handleAffiliateAction('approve')}
              style={{ fontSize: '18px' }}
              disabled={processing}
            >
              Approve
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Unapprove">
          <span>
            <IconButton
              aria-label="unapprove"
              onClick={() => handleAffiliateAction('unapprove')}
              style={{ fontSize: '18px' }}
              disabled={processing}
            >
              Unapprove
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Block">
          <span>
            <IconButton
              aria-label="block"
              onClick={() => handleAffiliateAction('block')}
              style={{ fontSize: '18px' }}
              disabled={processing}
            >
              Block
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </MuiToolbar>
  );
};
