import { DeleteButton, SaveButton, Toolbar } from 'react-admin';

export const ToolbarAlwaysOnSaveButton = ({ localStorageValue = '' }) => {
  const handleClick = () => {
    localStorageValue && localStorage.setItem('aggregate', localStorageValue);
  };

  return (
    <Toolbar>
      <SaveButton
        onClick={handleClick}
        alwaysEnable
      />
      <DeleteButton />
    </Toolbar>
  );
};
