import { useState, useEffect } from 'react';
import {
   Create,
   TabbedForm,
   TextInput,
   NumberInput,
   BooleanInput,
   SelectInput,
   CheckboxGroupInput,
   ReferenceArrayInput,
   AutocompleteArrayInput,
   required,
   SelectArrayInput,
   FormTab,
   ReferenceInput,
   AutocompleteInput,
} from 'react-admin'
import { setChoices } from '../../utils'
import { transformArrayField, convertStringToArray } from '../../utils/helpers'
import { Brand } from '../../config/constants'
import { GoBackButton } from '../../components'
import { getRandomApiKey } from './helpers/getRandomApiKey'
import { Button } from '@mui/material'
import { ToolbarAlwaysOnSaveButton } from '../../components/ToolbarAlwaysOnSaveButton'
import { MainDomainTrackingCard } from './components/MainDomainTrackingCard';
import { AdditionalDomainTrackingCard } from './components/AdditionalDomainTrackingCard';
import {
   processDomainTrackingData,
   handleDomainTrackingChange,
   setParsedPluginInfo,
   removeCronStatus,
   isDomainTrackingDisabled,
} from '../../utils/brandUtils';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';
import { useFetchEntities } from '../../hooks/useFetchEntities';

export const BrandCreate = props => {
   const [name, setName] = useState('')
   const [type, setType] = useState('')
   const [title, setTitle] = useState('')
   const [connectedBrandId, setConnectedBrandId] = useState(null)
   const [connectBrandsCustomers, setConnectBrandsCustomers] = useState([])

   const [domain, setDomain] = useState('')
   const [homeUrl, setHomeUrl] = useState('')
   const [siteKey, setSiteKey] = useState('')
   const [siteSecret, setSiteSecret] = useState('')
   const [isAllowTrack, setIsAllowTrack] = useState(false)

   const [pluginStatus, setPluginStatus] = useState('')
   const [pluginSiteKeyMatch, setPluginSiteKeyMatch] = useState(false)
   const [pluginSiteSecretMatch, setPluginSiteSecretMatch] = useState(false)
   const [calcTax, setCalcTax] = useState(false)
   const [calcShipping, setCalcShipping] = useState(false)
   const [pluginInfo, setPluginInfo] = useState({})

   const [multipleBy, setMultipleBy] = useState('')
   const [integrationFunc, setIntegrationFunc] = useState('')
   const [hasIframeTags, setHasIframeTags] = useState(false)

   const [conflictType, setConflictType] = useState('medium')
   const [conflictSystemsIds, setConflictSystemsIds] = useState([])
   const [uniqueConflictCookies, setUniqueConflictCookies] = useState('')
   const [additionalDomainTracking, setAdditionalDomainTracking] = useState([])

   const copiedRecordTemp = useProcessCopiedRecord('brand')
   const [copiedRecord, setCopiedRecord] = useState('')
   const domainTrackingDisabled = isDomainTrackingDisabled(type);

   const { total } = useFetchEntities('brand');

   useEffect(() => {
      if (!copiedRecordTemp) return;
  
      setCopiedRecord(copiedRecordTemp);
      setParsedPluginInfo(copiedRecordTemp.plugin_info, setPluginInfo);
      setHomeUrl(copiedRecordTemp.home_url);
      setType(copiedRecordTemp.type);
   }, [copiedRecordTemp]);  

   useEffect(() => {
      domainTrackingDisabled && setPluginInfo(removeCronStatus(pluginInfo));
   }, [domainTrackingDisabled]);

   const handleCronStatusChange = (event) => {
      const currentPluginInfo = typeof pluginInfo === "string" ? JSON.parse(pluginInfo) : pluginInfo;
    
      const updatedPluginInfo = {
         ...currentPluginInfo,
         cron_status: event.target.checked ? 'error' : 'success',
      };

      setPluginInfo(updatedPluginInfo);
      copiedRecord && setCopiedRecord(prev => ({ ...prev, "plugin_info": JSON.stringify(updatedPluginInfo) }));
   };

   const handlePluginInfoManualChange = (event) => {
      const updatedPluginInfo = JSON.parse(event.target.value);
      setPluginInfo(updatedPluginInfo);
      copiedRecord && setCopiedRecord(prev => ({ ...prev, "plugin_info": event.target.value }));
   };

   const handleAdditionalDomainTrackingChange = (source, value) => {
      handleDomainTrackingChange({ 
        source, 
        value, 
        setAdditionalDomainTracking, 
        ...(copiedRecord && { setCopiedRecord })
      });
   };

   const onSwitchChange = (setterFunction, field) => ({ target: { checked } }) => {
      setterFunction(checked);
      copiedRecord && setCopiedRecord(prev => ({ ...prev, [field]: checked }));
   };

   const transform = data => {
      let parsedPluginInfo = copiedRecord?.plugin_info ? JSON.parse(copiedRecord.plugin_info) : pluginInfo;

      if (domainTrackingDisabled) {
         parsedPluginInfo = removeCronStatus(parsedPluginInfo);
      }

      return {
         ...data,
         unique_conflict_cookies: convertStringToArray(data.unique_conflict_cookies),
         connect_brands_customers: transformArrayField(data.connect_brands_customers),
         integration_func: data.integration_func ? data.integration_func : '',
         plugin_info: JSON.stringify(parsedPluginInfo).replace(/\s/g, ''),
         connected_brand_id: data.connected_brand_id || null,
         additional_domain_tracking: domainTrackingDisabled ? [] : processDomainTrackingData(data.additional_domain_tracking),
      }
   }

   const initRecord = {
      name,
      type,
      title,
      connected_brand_id: connectedBrandId || null,
      connect_brands_customers: connectBrandsCustomers,
      domain,
      home_url: homeUrl,
      site_key: siteKey,
      site_secret: siteSecret,
      is_allow_track: isAllowTrack,
      plugin_status: pluginStatus,
      plugin_site_key_match: pluginSiteKeyMatch,
      plugin_site_secret_match: pluginSiteSecretMatch,
      calc_tax: calcTax,
      calc_shipping: calcShipping,
      plugin_info: typeof pluginInfo === 'string' ? pluginInfo : JSON.stringify(pluginInfo),
      multiple_by: multipleBy,
      integration_func: integrationFunc,
      has_iframe_tags: hasIframeTags,
      conflict_type: conflictType,
      conflict_systems_ids: conflictSystemsIds,
      unique_conflict_cookies: uniqueConflictCookies,
      additional_domain_tracking: additionalDomainTracking,
   }

   const onChange = (setFunc, field) => ({target}) => {
      setFunc(target.value)
      copiedRecord && setCopiedRecord(prev => ({ ...prev, [field]: target.value }));
   }

   return (
      <>
         <GoBackButton path="/brand"/>
         <Create {...props} transform={transform} record={copiedRecord ? {...copiedRecord, site_key: siteKey} : initRecord}>
            <TabbedForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
               <FormTab label="main">
                  <TextInput source="name"
                             validate={[required()]}
                             onChange={onChange(setName, "name")}
                  />
                  <SelectInput source="type"
                               label="Integration type"
                               validate={[required()]}
                               choices={setChoices(Brand.integrationTypes)}
                               onChange={onChange(setType, "type")}
                               style={{minWidth: 200}}
                  />
                  <TextInput source="title"
                             validate={[required()]}
                             onChange={onChange(setTitle, "title")}
                  />
                  <ReferenceInput
                     source="connected_brand_id"
                     reference="brand"
                     sort={{ field: "id", order: "ASC" }}
                     perPage={total}
                  >
                     <AutocompleteInput
                        label="Connected Brand ID"
                        className="w-50"
                        optionText={(choice) => `${choice.id} - ${choice.name}`}
                        onChange={(value) => {
                           setConnectedBrandId(value);
                           copiedRecord && setCopiedRecord(prev => ({ ...prev, ["connected_brand_id"]: value }));
                        }}
                     />
                  </ReferenceInput>
                  <ReferenceArrayInput
                     source="connect_brands_customers"
                     reference="brand"
                     sort={{field: 'id', order: 'ASC'}}
                     perPage={total}
                  >
                     <AutocompleteArrayInput
                        label="Connect Brands Customers"
                        optionText={(choice) => `${choice.id} - ${choice.name}`}
                        onChange={(value) => {
                           setConnectBrandsCustomers(value);
                           copiedRecord && setCopiedRecord(prev => ({ ...prev, "connect_brands_customers": value }));
                        }}
                        className="w-50"
                     />
                  </ReferenceArrayInput>
               </FormTab>
               <FormTab label="details" path="details">
                  <TextInput source="domain"
                             fullWidth
                             validate={[required()]}
                             onChange={onChange(setDomain, "domain")}
                  />
                  <TextInput source="home_url"
                             fullWidth
                             validate={[required()]}
                           onChange={onChange(setHomeUrl, "home_url")}
                  />
                  <div className="d-flex align-items-center w-50">
                     <TextInput source="site_key"
                                className="w-50"
                                validate={[required()]}
                     />
                     <Button className="h-50 mx-3 mb-3"
                             color="primary"
                             variant="contained"
                             onClick={() => setSiteKey(getRandomApiKey())}
                             type="button"
                     >
                        Generate Api key
                     </Button>
                  </div>
                  <TextInput source="site_secret"
                             validate={[required()]}
                             fullWidth
                             onChange={onChange(setSiteSecret, "site_secret")}
                  />
                  <BooleanInput
                     source="is_allow_track"
                     label="Tracking Status"
                     onChange={onSwitchChange(setIsAllowTrack, 'is_allow_track')}
                  />
               </FormTab>

               <FormTab label="plugin" path="plugin">
                  <SelectInput source="plugin_status"
                               choices={setChoices(Brand.pluginStatus)}
                               onChange={onChange(setPluginStatus, "plugin_status")}
                               validate={[required()]}
                  />
                  <BooleanInput
                     source="plugin_site_key_match"
                     onChange={onSwitchChange(setPluginSiteKeyMatch, 'plugin_site_key_match')}
                  />
                  <BooleanInput
                     source="plugin_site_secret_match"
                     onChange={onSwitchChange(setPluginSiteSecretMatch, 'plugin_site_secret_match')}
                  />
                  <BooleanInput
                     source="calc_tax"
                     onChange={onSwitchChange(setCalcTax, 'calc_tax')}
                  />
                  <BooleanInput
                     source="calc_shipping"
                     onChange={onSwitchChange(setCalcShipping, 'calc_shipping')}
                  />
                  <TextInput
                     source="plugin_info"
                     multiline
                     fullWidth
                     helperText="Plugin integration information"
                     defaultValue='{}'
                     onChange={handlePluginInfoManualChange}
                  />
                  <NumberInput source="multiple_by"
                               validate={[required()]}
                               onChange={onChange(setMultipleBy, "multiple_by")}
                  />
                  <CheckboxGroupInput source="integration_func"
                                      choices={setChoices(Brand.integrations)}
                                      onChange={event => {
                                          setIntegrationFunc(event)
                                          copiedRecord && setCopiedRecord(prev => ({ ...prev, "integration_func": event }))
                                      }}
                  />
                  <BooleanInput
                     source="has_iframe_tags"
                     onChange={onSwitchChange(setHasIframeTags, 'has_iframe_tags')}
                  />
               </FormTab>
               <FormTab label="conflict systems" path="conflict-systems">
                  <SelectInput source="conflict_type"
                               choices={setChoices(Brand.conflictTypes)}
                               onChange={onChange(setConflictType, "conflict_type")}
                               validate={[required()]}
                  />
                  <ReferenceArrayInput label="Conflict Systems names"
                                       source="conflict_systems_ids"
                                       reference="conflict-settings"
                                       sort={{field: 'name', order: 'ASC'}}>
                     <SelectArrayInput optionText="name"
                                       optionValue="name"
                                       fullWidth
                                       onChange={onChange(setConflictSystemsIds, "conflict_systems_ids")}
                     />
                  </ReferenceArrayInput>
                  <TextInput source="unique_conflict_cookies"
                             fullWidth
                             helperText="Please enter values separating them with coma in format: systemId-value"
                             onChange={onChange(setUniqueConflictCookies, "unique_conflict_cookies")}
                  />
               </FormTab>

               <FormTab label="Domain tracking">
                  <MainDomainTrackingCard
                     handleCronStatusChange={handleCronStatusChange}
                     pluginInfo={pluginInfo}
                     type={type}
                     initCronStatus={pluginInfo.cron_status}
                  />
                  {!domainTrackingDisabled && (
                     <AdditionalDomainTrackingCard 
                        source="additional_domain_tracking"
                        mainDomain={homeUrl}
                        onDomainChange={handleAdditionalDomainTrackingChange}
                        onCronStatusChange={handleAdditionalDomainTrackingChange}
                        getDomainValue
                        setAdditionalDomainTracking={setAdditionalDomainTracking}
                        {...(copiedRecord && { setCopiedRecord })}
                     />
                  )}
               </FormTab>
            </TabbedForm>
         </Create>
      </>
   )
}
