import { format, parseISO, addMinutes } from 'date-fns';

export const formatToUtc = (isoDateString) => {
  const date = parseISO(isoDateString);
  const utcDate = addMinutes(date, date.getTimezoneOffset());

  return format(utcDate, "dd/MM/yyyy, HH:mm:ss");
};

export const isWithinThirtyMinutes = (dateString) => {
  const providedDateUTC = new Date(dateString);
  const currentTimeUTC = new Date();
  const thirtyMinutesAgoUTC = new Date(currentTimeUTC.getTime() - 30 * 60 * 1000);

  return providedDateUTC > thirtyMinutesAgoUTC;
};
