import { fetchUtils } from 'react-admin';

export const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3500';

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('accessToken');

  options.credentials = 'include';
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

