import { BooleanInput } from 'react-admin';
import { getBooleanValue } from '../../../utils/brandUtils';

export const CustomBooleanInput = ({ source, record = {}, onChange, ...rest }) => {
  const field = source.split('.').pop();
  const booleanValue = getBooleanValue(record, field);

  const handleChange = (event) => {
    onChange && onChange(event.target.checked ? 'error' : 'success');
  };

  return (
    <BooleanInput
      source={source}
      onChange={handleChange}
      checked={booleanValue}
      {...rest}
      label="Rest API"
    />
  );
};
