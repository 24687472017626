import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  number,
} from 'react-admin';
import { GoBackButton, ToolbarAlwaysOnSaveButton } from '../../components';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const AffPriorityCreate = props => {
  const { total } = useFetchEntities('brand');

  return (
    <>
      <GoBackButton path="/aff-priorities"/>
      <Create {...props} record={useProcessCopiedRecord('aff-priorities') || null}>
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
          <ReferenceInput
            label="Brand"
            source="brand_id"
            reference="brand"
            sort={{field: "id", order: "ASC"}}
            perPage={total}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.id} - ${choice.name}`} 
            />
          </ReferenceInput>
          <ReferenceInput
            label="Affiliate"
            source="affilate_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
            sort={{field: "affiliate_id", order: "ASC"}}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
            />
          </ReferenceInput>
          <TextInput source="priority" validate={[required(), number()]}/>
        </SimpleForm>
      </Create>
    </>
  )
}
