import * as React from 'react'
import {
   List,
   Datagrid,
   TextField,
   TextInput,
   SelectInput,
} from 'react-admin'

import { RowsPerPage, BulkActionCopy } from '../../components'
import { rolesChoices } from './helpers/constants'
import { RoleNameField } from './helpers/RoleNameField'
import { useRowStyle } from '../../hooks/useRowStyle'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'

const filters = [
   <TextInput source="search"
              label="ID, Username"
              alwaysOn
              className="search-input-width"/>,
   <SelectInput source="role"
                label="Role"
                choices={rolesChoices}
   />,
]


export const UsersList = props => {
   const rowClassName = useRowStyle()

   return (
      <List {...props}
            pagination={<RowsPerPage/>}
            filters={filters}
            className="list-admin-wrapper"
            actions={<ToolBarRowStyleToggle/>}
      >
         <Datagrid rowClick="edit" className={rowClassName} bulkActionButtons={<BulkActionCopy/>}>
            <TextField source="id"/>
            <TextField source="username"/>
            <TextField source="password"/>
            <RoleNameField source="role" label="Role"/>
         </Datagrid>
      </List>
   )
}
