import { useRecordContext } from 'react-admin';
import { whiteSpaceNoWrap } from '../config/constants';
import { formatToUtc } from '../utils/dateUtils';

export const DateInOneRow = ({ source, ...props }) => {
  const record = useRecordContext();
  const dateValue = record?.[source];

  return dateValue ? <span style={props.style || whiteSpaceNoWrap}>{formatToUtc(dateValue)}</span> : null;
};
