import {
   List,
   Datagrid,
   TextField,
   FunctionField,
} from 'react-admin'
import { RowsPerPage, DateInOneRow, BulkActionCopy } from '../../components'
import { ActionsWithoutCreate } from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { useRedirectIfNoFilter } from '../../hooks/useRedirectIfNoFilter'
import { getRowWithLink } from '../../utils/helpers'
import { compareAggregateValue } from '../../utils/aggregateTablesUtils'
import { getHashedEmailsFilters } from '../../utils/filters/blockHashedEmailsFilters';

export const BlockHashedEmailsList = props => {
   const rowClassName = useRowStyle()
   const { entities: brands, total: brandsCount } = useFetchEntities('brand');
   const shouldRedirect = compareAggregateValue('block_hashed_emails_list');

   useRedirectIfNoFilter({
      filterKey: 'brand_id',
      redirectLink: 'block-hashed-emails-aggregated',
      sourceKey: 'RaStore.block-hashed-emails.listParams',
      targetKey: 'RaStore.block-hashed-emails-aggregated.listParams',
      shouldRedirect
   });

   return (
      <List
         {...props}
         pagination={<RowsPerPage/>}
         filters={getHashedEmailsFilters(brandsCount)}
         className="list-admin-wrapper"
         actions={<ActionsWithoutCreate tableName='block-hashed-emails' showUploadCsv />}
      >
         <Datagrid className={rowClassName} bulkActionButtons={<BulkActionCopy showCopyButton={false} />}>
            <TextField source="id"/>
            <FunctionField
               source="brand_id"
               label="Brand"
               render={record => getRowWithLink('brand', record.brand_id, brands)}
            />
            <TextField source="hashed_email" label="Hashed Email"/>
            <DateInOneRow source="update_time" label="Update time" showTime={true}/>
         </Datagrid>
      </List>
   )
}
