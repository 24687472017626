import React from 'react'
import {
  SimpleForm,
  TextInput,
  Edit,
  SelectInput,
  useNotify,
  number,
  required,
} from 'react-admin'
import { AsideHistory, GoBackButton, CustomEditToolbar } from '../../components'
import { setChoices } from '../../utils/setChoices'
import { validateFilters } from '../../utils/helpers'
import { elasticCronNames, elasticCronStatuses, elasticServiceNames } from '../../config/constants'

export const ElasticCronSettingsEdit = props => {
  const notify = useNotify()

  const onError = () => {
    notify('Something went wrong', {type: 'error'})
  }

  return (
    <>
      <GoBackButton path="/elastic-cron-settings" />
      <Edit {...props} aside={<AsideHistory/>} onFailure={onError} mutationMode="pessimistic">
        <SimpleForm toolbar={<CustomEditToolbar />}>
          <TextInput source="id" label="ID" disabled={true} />
          <SelectInput
            source="cron_name"
            label="Cron Name"
            choices={setChoices(elasticCronNames)}
            disabled={true}
          />
          <SelectInput
            source="status"
            label="Status"
            choices={setChoices(elasticCronStatuses)}
            validate={[required()]}
          />
          <SelectInput
            source="service_name"
            label="Service Name"
            choices={elasticServiceNames}
          />
          <TextInput 
            source="filters" 
            label="Filters" 
            format={filters => {
              if (typeof filters === "string") return filters

              return filters ? JSON.stringify(filters) : ""
            }}
            parse={filters => {
              try {
                  return JSON.parse(filters)
              } catch (err) {
                  return filters
              }
            }}
            className="w-50" 
            validate={validateFilters}
          />
          <TextInput source="current_id" label="Current ID" validate={[number()]} className="w-50"/>
        </SimpleForm>
      </Edit>
    </>
  )
}
