import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const getAffApiSettingsFilters = (total) => [
  <TextInput
    source="search"
    label="ID, Affiliate ID, API key, Update Time"
    alwaysOn
    className="search-input-width"
  />,
  <ReferenceInput
    label="Affilate"
    source="affiliate_id"
    reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
    sort={{ field: 'affiliate_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.affiliate_name}
    />
  </ReferenceInput>,
  <TextInput source="api_key" label="API key" />,
  <ReferenceInput
    label="Brand"
    source="brands_limit"
    reference="brand"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={total}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.name}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Offer"
    source="offers_limit"
    reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
    sort={{ field: 'offer_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-md"
      optionText={(choice) => choice.offer_name}
    />
  </ReferenceInput>,
];
