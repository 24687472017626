import { Create } from 'react-admin';
import { GoBackButton, ToolbarAlwaysOnSaveButton } from '../../components';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';
import { AffiliateApiSettingsForm } from './AffiliateApiSettingsForm';

export const AffiliateApiSettingCreate = (props) => (
  <>
    <GoBackButton path="/affiliate-api-settings" />
    <Create
      {...props}
      record={useProcessCopiedRecord('affiliate-api-settings') || null}
    >
      <AffiliateApiSettingsForm
        mode="create"
        formProps={{ toolbar: <ToolbarAlwaysOnSaveButton /> }}
      />
    </Create>
  </>
);
