import { useRecordContext } from 'react-admin'

export const FixTypeNames = props => {
  let {source} = props
  let record = useRecordContext(props)

  if (record.context?.brand.type) {
    source = 'type'
    record = record.context.brand
  }

  if (record[source] === 'shopify') {
    record[source] = 'Shopify'
  } else if (record[source] === 'wp') {
    record[source] = 'WordPress'
  } else if (record[source] === 'sdk') {
    record[source] = 'SDK'
  } else if (record[source] === 'custom') {
    record[source] = 'Custom PostBack'
  } else if (record[source] === 'bigcommerce') {
    record[source] = 'BigCommerce'
  } else if (record[source] === 'magento') {
    record[source] = 'Magento'
  }

  return (
    <span>{record[source]}</span>
  )
}