import {
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  number,
  required,
} from 'react-admin'
import { GoBackButton, ToolbarAlwaysOnSaveButton } from '../../components'
import { setChoices } from '../../utils/setChoices'
import { validateFilters } from '../../utils/helpers'
import { elasticCronNames, elasticCronStatuses, elasticServiceNames } from '../../config/constants'
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';

export const ElasticCronSettingsCreate = props => {
  return (
    <>
      <GoBackButton path="/elastic-cron-settings"/>
      <Create {...props} record={useProcessCopiedRecord('elastic-cron-settings') || null}>
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton />}>
          <SelectInput 
            source="cron_name"
            label="Cron Name"
            choices={setChoices(elasticCronNames)}
            validate={[required()]}
          />
          <SelectInput 
            source="status"
            label="Status"
            choices={setChoices(elasticCronStatuses)}
            validate={[required()]}
          />
          <SelectInput 
            source="service_name"
            label="Service Name"
            choices={elasticServiceNames}
          />
          <TextInput 
            source="filters" 
            label="Filters" 
            format={filters => {
              if (typeof filters === "string") return filters

              return filters ? JSON.stringify(filters) : ""
            }}
            parse={filters => {
              try {
                  return JSON.parse(filters)
              } catch (err) {
                  return filters
              }
            }}
            className="w-50" 
            validate={validateFilters}
          />
          <TextInput source="current_id" label="Current ID" validate={[number()]} className="w-50"/>
        </SimpleForm>
      </Create>
    </>
  )
}
