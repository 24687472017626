import {
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { setChoices } from '../setChoices';
import { PreApprovalSettings, preApprovalSettingsCheckers } from '../../config/constants';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const preApprovalFilters = [
  <TextInput
    source="search"
    label="ID, Affiliate ID, Offer ID"
    alwaysOn
    className="search-input-width"
  />,
  <ReferenceInput
    label="Affilate"
    source="affiliate_id"
    reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
    sort={{ field: 'affiliate_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.affiliate_name}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Offer"
    source="offer_id"
    reference={REQUESTS_ROUTES.GET_ALL_OFFERS}
    sort={{ field: 'offer_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-md"
      optionText={(choice) => choice.offer_name}
    />
  </ReferenceInput>,
  <SelectInput
    source="checker"
    label="Checker"
    choices={setChoices(preApprovalSettingsCheckers)}
  />,
  <SelectInput
    source="result"
    label="Result"
    choices={setChoices(PreApprovalSettings.decisionResult)}
  />,
  <TextInput source="status" />,
  <SelectInput
    source="fallback_result"
    label="Fallback Result"
    choices={setChoices(PreApprovalSettings.decisionFallbackResult)}
  />,
  <TextInput source="commission_structure" />,
  <TextInput source="untracked_period" />,
];

export const getPreApprovalAggregatedFilters = (aggregateValue) => [
  <TextInput
    source="search"
    label={`Count, ${
      aggregateValue === 'affiliate_id' ? 'Affiliate ID' : 'Offer ID'
    }, Update Time`}
    alwaysOn
    className="search-input-width"
  />,
  aggregateValue === 'affiliate_id' ? (
    <ReferenceInput
      label="Affiliate"
      source="affiliate_id"
      reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
      sort={{ field: 'affiliate_name', order: 'ASC' }}
    >
      <AutocompleteInput
        className="search-input-width"
        optionText={(choice) => choice.affiliate_name}
      />
    </ReferenceInput>
  ) : (
    <ReferenceInput
      label="Offer"
      source="offer_id"
      reference={REQUESTS_ROUTES.GET_ALL_OFFERS}
      sort={{ field: 'offer_name', order: 'ASC' }}
    >
      <AutocompleteInput
        className="search-input-md"
        optionText={(choice) => choice.offer_name}
      />
    </ReferenceInput>
  ),
];
