import { useState, useEffect } from 'react';
import {
  BooleanInput,
  Edit,
  NumberInput,
  TextInput,
  SelectInput,
  CheckboxGroupInput,
  SelectArrayInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TabbedForm,
  FormTab,
  useNotify,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin';
import { Button } from '@mui/material';
import { Brand } from '../../config/constants';
import { setChoices } from '../../utils';
import { convertStringToArray } from '../../utils/helpers';
import { processDomainTrackingData, isDomainTrackingDisabled } from '../../utils/brandUtils';
import { AsideHistory, GoBackButton, CustomEditToolbar } from '../../components';
import { MainDomainTrackingCard } from './components/MainDomainTrackingCard';
import { AdditionalDomainTrackingCard } from './components/AdditionalDomainTrackingCard';
import { useFetchEntities } from '../../hooks/useFetchEntities';

export const BrandEdit = props => {
  const notify = useNotify()
  const [record, setRecord] = useState()
  const [integrationType, setIntegrationType] = useState('')
  const [pluginInfo, setPluginInfo] = useState({});
  const [homeUrl, setHomeUrl] = useState('');
  const [initCronStatus, setInitCronStatus] = useState('');
  const [enableButton, setEnableButton] = useState(false);
  const domainTrackingDisabled = isDomainTrackingDisabled(integrationType);
  const { total } = useFetchEntities('brand');

  useEffect(() => {
    if (!record) return;
  
    const { plugin_info, home_url } = record;

    plugin_info && setPluginInfo(JSON.parse(plugin_info));
    plugin_info && setInitCronStatus(JSON.parse(plugin_info).cron_status);
    home_url && setHomeUrl(home_url);
  }, [record]);

  const handleCronStatusChange = (event) => {
    setPluginInfo(prevPluginInfo => ({
      ...prevPluginInfo,
      cron_status: event.target.checked ? 'error' : 'success'
    }));
    setEnableButton(true);
  };

  const handlePluginInfoManualChange = (event) => {
    try {
      setPluginInfo(JSON.parse(event.target.value));
    } catch (error) {
      notify('Error parsing JSON. Please check the format.', 'warning');
    }
  };

  const transform = async data => {
    try {
      return {
        ...data,
        connect_brands_customers: data.connect_brands_customers.length > 0
          ? data.connect_brands_customers
          : null,
        plugin_info: JSON.stringify({
          ...pluginInfo,
          ...(domainTrackingDisabled && { cron_status: undefined })
        }).replace(/\s/g, '').replace(/,"cron_status":undefined/g, ''),
        unique_conflict_cookies: convertStringToArray(data.unique_conflict_cookies),
        additional_domain_tracking: domainTrackingDisabled ? [] : processDomainTrackingData(data.additional_domain_tracking),
      }
    } catch (err) {
      notify('Wrong format of JSON object (Plugin Info field)', 'error')
      await new Promise(r => setTimeout(r, 2000));
      window.location.reload()
    }
  }

  return (
    <>
      <GoBackButton path="/brand"/>
      <Edit {...props} transform={transform} aside={<AsideHistory isCheckTime={true}/>} title={record?.name}>
        <TabbedForm toolbar={<CustomEditToolbar alwaysEnable={enableButton} />}>
          <FormTab label="main">
            <div className="row w-100">
              <div className="col-6">
                <TextInput
                  source="name"
                  helperText="Name will show next to the order ID in the CBDAffs tune system"
                  validate={[required()]}
                />
              </div>
              <div className="col-6">
                <Button variant="contained"
                        color="primary"
                        onClick={() => {
                          navigator.clipboard.writeText(record.site_key)
                          notify('Site key copied to clipboard', {type: 'info'})
                        }}
                >copy site key</Button>
              </div>
            </div>

            <SelectInput
              source="type"
              label="Integration type"
              choices={setChoices(Brand.integrationTypes)}
              onChange={({target}) => { setIntegrationType(target.value) }}
              style={{minWidth: 200}}
              validate={[required()]}
            />
            <TextInput source="title" className="w-50" validate={[required()]} />
            <ReferenceInput
              source="connected_brand_id"
              reference="brand"
              sort={{field: "id", order: "ASC"}}
              perPage={total}
            >
              <AutocompleteInput
                label="Connected Brand ID"
                optionText={(choice) => `${choice.id} - ${choice.name}`}
                className="w-50"
              />
            </ReferenceInput>
            <ReferenceArrayInput
              source="connect_brands_customers"
              reference="brand"
              sort={{field: 'id', order: 'ASC'}}
              perPage={total}
            >
              <AutocompleteArrayInput
                label="Connect Brands Customers"
                optionText={(choice) => `${choice.id} - ${choice.name}`}
                className="w-50"
              />
            </ReferenceArrayInput>
          </FormTab>

          <FormTab label="details" path="details">
            <TextInput source="name" disabled={true} className="w-50"/>
            <TextInput
              source="domain"
              fullWidth
              helperText="Domain must be clean and not include http:// or https:// or www."
              validate={[required()]}
            />
            <TextInput source="home_url"
                       fullWidth
                       validate={[required()]}
                       helperText={
                        integrationType === 'shopify' || integrationType === 'Shopify' ?
                           'Format = https://xxx.myshopify.com':
                           integrationType === 'bigcommerce' || integrationType === 'BigCommerce' ?
                              'Format = https://xxx.mybigcommerce.com' :
                              'Format = https://xxx.com'
                       }
                      onChange={(event) => setHomeUrl(event.target.value)}
            />
            <TextInput source="site_key" fullWidth validate={[required()]} />
            <TextInput source="site_secret" fullWidth validate={[required()]}/>
            <BooleanInput source="is_allow_track" label=" Tracking Status"/>
          </FormTab>

          <FormTab label="plugin" path="plugin">
            <SelectInput source="plugin_status" choices={setChoices(Brand.pluginStatus)} validate={[required()]}/>
            <BooleanInput source="plugin_site_key_match"
                          helperText="Automatically checked by system once integration is working"
            />
            <BooleanInput source="plugin_site_secret_match"
                          helperText="Automatically checked by system once integration is working"
            />
            <BooleanInput source="calc_tax"
                          helperText="Include tax amount in sale amount"
            />
            <BooleanInput source="calc_shipping"
                          helperText="Include shipping amount in sale amount"
            />
            <TextInput
              source="plugin_info"
              multiline
              fullWidth
              helperText="Plugin integration information"
              defaultValue='{}'
              onChange={handlePluginInfoManualChange}
            />
            <NumberInput
              source="multiple_by"
              helperText="Multiply sale amount by X"
              validate={[required()]}
            />
            <CheckboxGroupInput source="integration_func" choices={setChoices(Brand.integrations)}/>
            <BooleanInput source="has_iframe_tags"/>
          </FormTab>

          <FormTab label="conflict systems" path="conflict-systems">
            <SelectInput source="conflict_type" choices={setChoices(Brand.conflictTypes)} validate={[required()]}/>


            <ReferenceArrayInput label="Conflict Systems names"
                                 source="conflict_systems_ids"
                                 reference="conflict-settings"
                                 sort={{field: 'name', order: 'ASC'}}>

              <SelectArrayInput optionText="name" optionValue="name" fullWidth/>
            </ReferenceArrayInput>
            <TextInput source="unique_conflict_cookies" fullWidth
                       helperText="Please enter values separating them with coma in format: systemId-value"/>
            <FunctionField render={record => {
              !integrationType && setIntegrationType(record.type)
              setRecord(record)
            }}
                           label=" "
            />
          </FormTab>

          <FormTab label="Domain tracking">
            <MainDomainTrackingCard
              handleCronStatusChange={handleCronStatusChange}
              pluginInfo={pluginInfo}
              type={integrationType}
              initCronStatus={initCronStatus}
            />
            {!domainTrackingDisabled && (
              <AdditionalDomainTrackingCard
                source="additional_domain_tracking"
                mainDomain={homeUrl}
              />
            )}
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  )
}
