import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

export const GoBackButton = ({path}) => {
  return (
    <Link to={path} style={{textDecoration: 'none', width: '70px'}}>
      <Button style={{width: '70px', margin: '20px 0 10px 0'}} variant="contained" color="secondary">
        Back
      </Button>
    </Link>
  )
}

