import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  number,
} from 'react-admin'
import { AsideHistory, GoBackButton, CustomEditToolbar } from '../../components'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const AffPriorityEdit = props => {
  const { total } = useFetchEntities('brand')

  return (
    <>
      <GoBackButton path="/aff-priorities"/>
      <Edit {...props} aside={<AsideHistory/>}>
        <SimpleForm toolbar={<CustomEditToolbar />}>
          <ReferenceInput
            label="Brand"
            source="brand_id"
            reference="brand"
            sort={{field: "id", order: "ASC"}}
            perPage={total}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.id} - ${choice.name}`} 
            />
          </ReferenceInput>
          <ReferenceInput
            label="Affiliate"
            source="affilate_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
            sort={{field: "affiliate_id", order: "ASC"}}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
            />
          </ReferenceInput>
          <TextInput source="priority" validate={[required(), number()]}/>
        </SimpleForm>
      </Edit>
    </>
  )
}
