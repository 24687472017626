import {
   TopToolbar,
   ExportButton,
   FilterButton,
   useTheme,
} from 'react-admin'
import { darkTheme, lightTheme } from '../Layout/themes'
import { Button, Tooltip } from '@mui/material'
import { ReorderRounded } from '@mui/icons-material'
import { UploadButton } from './UploadButton'
import { AggregateBySelect } from './AggregateBySelect'
import { RefreshTuneEntitiesButton } from './RefreshTuneEntitiesButton';
import { LastTuneUpdateDisplay } from './LastTuneUpdateDisplay';

export const ActionsWithoutCreate = ({
   tableName = '',
   showUploadCsv = false,
   showRefreshEntitiesButton = false,
   showLastTuneUpdate = false
}) => {
   const [theme, setTheme] = useTheme()

   return (
      <TopToolbar>
         <LastTuneUpdateDisplay showLastTuneUpdate={showLastTuneUpdate} />
         {showRefreshEntitiesButton && (
            <RefreshTuneEntitiesButton style={{ minWidth: '226px', fontSize: '12px' }} />
         )}
         {tableName && <AggregateBySelect tableName={tableName} />}
         <Button
            onClick={() => {
               const value = !localStorage.getItem('RaStore.isRowStyleActive') ? '1' : ''
               localStorage.setItem('RaStore.isRowStyleActive', value)
               if (theme.palette.mode === 'dark') {
                  setTheme({...darkTheme, isRowStyleActive: value})
               } else {
                  setTheme({...lightTheme, isRowStyleActive: value})
               }

            }}
         >
            <Tooltip title="Color every second row" placement="bottom">
               <ReorderRounded color="action" fontSize="small"/>
            </Tooltip>
         </Button>
         <FilterButton />
         <ExportButton />
         {showUploadCsv && <UploadButton />}
      </TopToolbar>
   )
}
