import { useEffect } from 'react';

export const useLocalStorageEffect = (storageKey, filterKey) => {
  useEffect(() => {
    const storedParams = JSON.parse(localStorage.getItem(storageKey) || '{}');
    if (storedParams.filter && storedParams.filter[filterKey]) {
      delete storedParams.filter[filterKey];
      localStorage.setItem(storageKey, JSON.stringify(storedParams));
    }
  }, [storageKey, filterKey]);
};
