import React, { useState } from 'react'
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import { GoBackButton } from '../../components'
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  useNotify,
  required,
} from 'react-admin'
import { setChoices } from '../../utils/setChoices'
import { cronNames } from '../../config/constants'
import { ToolbarAlwaysOnSaveButton } from '../../components/ToolbarAlwaysOnSaveButton'

export const CronSettingsCreate = props => {
  const [expression, setExpression] = useState('0 0 * * *')
  const [cronName, setCronName] = useState('')
  const notify = useNotify()

  const onError = () => {
    notify('Something went wrong', {type: 'error'})
  }
  const initRecord = {
    expression,
    cron_name: cronName,
  }

  return (
    <>
      <GoBackButton path="/cron-settings"/>
      <Create {...props} mutationOptions={{onError}} record={initRecord}>
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
          <SelectInput
            source="cron_name"
            label="Cron Name"
            choices={setChoices(cronNames)}
            onChange={({target}) => setCronName(target.value)}
            validate={[required()]}
          />
          <BooleanInput source="is_enabled" label="Is Enabled"/>
          <div className="w-100">
            <Cron value={expression} setValue={setExpression}/>
          </div>
          <TextInput
            source="time_expression"
            label="Time Expression"
            isRequired={true}
            format={() => expression}
            disabled={true}
          />
        </SimpleForm>
      </Create>
    </>
  )
}
