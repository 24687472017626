import {
  Create,
  SimpleForm,
  TextInput,
  number,
  required,
} from 'react-admin';
import { GoBackButton, ToolbarAlwaysOnSaveButton } from '../../components';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';

export const ShopifySessionsCreate = props => {
  return (
    <>
      <GoBackButton path="/shopify-sessions" />
      <Create {...props} record={useProcessCopiedRecord('shopify-sessions') || null}>
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
          <TextInput source="id" className="w-50" validate={[required()]} />
          <TextInput source="shop" className="w-50" validate={[required()]} />
          <TextInput source="state" className="w-50" validate={[required()]} />
          <TextInput source="isOnline" validate={[number(), required()]} />
          <TextInput source="scope" />
          <TextInput source="expires" validate={[number()]} />
          <TextInput source="onlineAccessInfo" />
          <TextInput source="accessToken" className="w-50" />
        </SimpleForm>
      </Create>
    </>
  )
}
