import React from 'react';
import { Autocomplete, TextField, FormControl } from '@mui/material';

const BrandSelector = ({ brands, selectedBrand, setSelectedBrand }) => {
  return (
    <FormControl sx={{ width: '400px' }}>
      <Autocomplete
        id="brand-selector"
        options={brands || []}
        getOptionLabel={(option) => `${option.id} - ${option.name}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedBrand}
        onChange={(_, newValue) => setSelectedBrand(newValue)}
        renderInput={(params) => <TextField {...params} label="Brand" />}
      />
    </FormControl>
  );
};

export default BrandSelector;
