import {
   useDataProvider,
   useNotify,
   useRedirect,
   useStore,
} from 'react-admin'
import { Button } from '@mui/material'
import { DeleteConfirmationButton } from './DeleteConfirmationButton'
import { usePermissionCheck } from '../hooks/usePermissionCheck'
import { ROLES } from '../config/constants/roles'

export const BulkActionCopy = ({ 
   showCopyButton = true,
   showDeleteButton = true,
   ...props
 }) => {
   const {resource, selectedIds} = props
   const recordId = selectedIds[0] || 0

   const notify = useNotify()
   const redirect = useRedirect()
   const dataProvider = useDataProvider()
   const isUserWithPermission = usePermissionCheck([
      ROLES.ADMIN,
      ROLES.BRANDS_MANAGER,
      ROLES.PUBLISHER_MANAGER,
      ROLES.MEDIA_BUYER
   ]);

   const [copiedRecord, setCopiedRecord] = useStore(`${resource}.record_copy`, null)

   const onClickCopy = async () => {
      if (selectedIds.length > 1) return notify('Please choose only one record to copy', {type: 'warning'})
      const {data} = await dataProvider.getOne(resource, {id: recordId})
      delete data.id

      setCopiedRecord(data)
      redirect(`/${resource}/create`)
   }

   return (
      <>
         {showCopyButton && (
            <Button size="small" onClick={onClickCopy}>Copy</Button>
         )}
         {showDeleteButton && isUserWithPermission && (
            <DeleteConfirmationButton resource={resource} selectedIds={selectedIds} />
         )}
      </>
   );
}