export const setChoices = (choices) =>  {
  if (!Array.isArray( choices )) {
    const prepared = [];

    for (let prop in choices) {
      prepared.push({ id: prop, name: choices[prop] });
    }
    return prepared;
  }

  return choices.map(item => {
    if (item === '') {
      return { id: '', name: 'No value'};
    }

    return { id: item, name: item };
  });
}
