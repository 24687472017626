import * as React from 'react';
import {
  Edit,
  SelectInput,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { setChoices } from '../../utils';
import { transformAffiliatesArray } from '../../utils/helpers';
import { PostbackSettings } from '../../config/constants';
import { GoBackButton, AsideHistory, CustomEditToolbar } from '../../components';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const PostbackSettingsEdit = (props) => {
  const { total } = useFetchEntities('brand');

  return (
    <>
      <GoBackButton path="/postback-settings" />
      <Edit
        aside={<AsideHistory />}
        {...props}
        transform={transformAffiliatesArray}
      >
        <SimpleForm toolbar={<CustomEditToolbar />}>
          <ReferenceInput
            label="Brand"
            source="brand_id"
            reference="brand"
            sort={{ field: 'id', order: 'ASC' }}
            perPage={total}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.id} - ${choice.name}`}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Offer"
            source="offer_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
            sort={{ field: 'offer_id', order: 'ASC' }}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) =>
                `${choice.offer_id} - ${choice.offer_name}`
              }
            />
          </ReferenceInput>
          <ReferenceArrayInput
            label="Affiliate IDs"
            source="affiliate_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
            sort={{ field: 'affiliate_id', order: 'ASC' }}
          >
            <AutocompleteArrayInput
              optionText={(choice) =>
                `${choice.affiliate_id} - ${choice.affiliate_name}`
              }
              optionValue="affiliate_id"
              fullWidth
            />
          </ReferenceArrayInput>
          <SelectInput
            source="postback_sub2"
            choices={setChoices(PostbackSettings.postbackSub2)}
          />
          <SelectInput
            source="postback_sub3"
            choices={setChoices(PostbackSettings.postbackSub3)}
          />
          <SelectInput
            source="postback_sub4"
            choices={setChoices(PostbackSettings.postbackSub4)}
          />
          <SelectInput
            source="postback_sub5"
            choices={setChoices(PostbackSettings.postbackSub5)}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};
