import { ROLES } from './roles';

export const allowedPermissions = {
  [ROLES.ADMIN]: ['*'],
  [ROLES.BRANDS_MANAGER]: [
    'brand-settings', 'brand', 'goals', 'goals-aggregated', 'coupons', 'notification-emails', 'event-settings', 'postback-settings',
    'block-event-settings', 'block-hashed-emails', 'coupon-settings', 'conflict-settings',
    'notification-emails-aggregated', 'block-event-settings-aggregated', 'block-hashed-emails-aggregated',
    'tune-management', 'offers'
  ],
  [ROLES.PUBLISHER_MANAGER]: ['tracking-settings', 'aff-priorities'],
  [ROLES.MEDIA_BUYER]: [
    'brand-settings', 'tracking-settings', 'brand-iframe-tags', 'aff-priorities',
    'event-settings', 'postback-settings',
  ],
  [ROLES.BRAND_MANAGER_VA]: [
    'brand-settings', 'brand', 'goals', 'goals-aggregated', 'conflict-settings',
  ],
};
