import { callAffiliateActionAPI } from '../fetchUtils/fetchTuneData';

export const constructAffiliatesQueryParams = ({
  offerId,
  page,
  pageSize,
  selectedFilter,
  sortModel,
  selectedAffiliate,
}) => {
  const range = [page * pageSize, (page + 1) * pageSize - 1];
  const affTypeFilter = selectedFilter.length ? `&aff_type_filter=${selectedFilter.join(',')}` : '';
  const sortField = sortModel[0]?.field || 'affiliate_id';
  const sortOrder = sortModel[0]?.sort || 'asc';
  const affiliateFilter = selectedAffiliate ? `&affiliate_id=${selectedAffiliate}` : '';

  return `?offer_id=${offerId}&range=${JSON.stringify(range)}&sortField=${sortField}&sortOrder=${sortOrder}${affTypeFilter}${affiliateFilter}`;
};

export const handleAffiliateAction = async ({
  actionType,
  selectedRows,
  setProcessing,
  setSelectedRows,
  affiliates,
  offerId,
  fetchAffiliates,
}) => {
  if (selectedRows.length === 0) return;

  setProcessing(true);
  const selectedAffiliates = selectedRows
    .map(id => affiliates.find(affiliate => affiliate.id === id))
    .filter(Boolean);

  const success = await callAffiliateActionAPI({ actionType, offerId, selectedAffiliates });
  if (success) {
    fetchAffiliates();
  }

  setSelectedRows([]);
  setProcessing(false);
};
