import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput
} from 'react-admin';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const offersManagementFilters = [
  <TextInput
    source="search"
    label="ID, Offer ID, Offer Name"
    alwaysOn
    className="search-input-width"
  />,
  <ReferenceInput
    label="Offer"
    source="offer_id"
    reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
    sort={{ field: 'offer_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-md"
      optionText={(choice) => choice.offer_name}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Tags"
    source="tag_id"
    reference={REQUESTS_ROUTES.GET_OFFER_TAGS}
    sort={{ field: 'tag_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-md"
      optionText={(choice) => choice.tag_name}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Channels"
    source="channel_tag_id"
    reference={REQUESTS_ROUTES.GET_CHANNELS}
    sort={{ field: 'tag_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-md"
      optionText={(choice) => choice.tag_name}
      label="Channels"
    />
  </ReferenceInput>,
  <ReferenceInput
      label="Conversion Types"
      source="conversion_type_tag_id"
      reference={REQUESTS_ROUTES.GET_CONVERSION_TYPES}
      sort={{ field: 'tag_name', order: 'ASC' }}
    >
      <AutocompleteInput
        className="search-input-md"
        optionText={(choice) => choice.tag_name}
        label="Conversion Types"
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Locations"
    source="location_name"
    reference={REQUESTS_ROUTES.GET_LOCATIONS}
    sort={{ field: 'location_name', order: 'ASC' }}
    perPage={300}
  >
    <AutocompleteInput
      className="search-input-md"
      optionText={(choice) => choice.location_name}
      label="Locations"
  />
  </ReferenceInput>,
];
