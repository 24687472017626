import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin'
import { GoBackButton, AsideHistory, CustomEditToolbar } from '../../components'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const BrandTagsEdit = props => {
  const { total } = useFetchEntities('brand')

  return (
    <>
      <GoBackButton path="/brand-iframe-tags"/>
      <Edit
        aside={<AsideHistory/>}
        {...props}
      >
      <SimpleForm toolbar={<CustomEditToolbar />}>
          <ReferenceInput
            label="Brand"
            source="brand_id"
            reference="brand"
            sort={{field: "id", order: "ASC"}}
            perPage={total}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.id} - ${choice.name}`} 
            />
          </ReferenceInput>
          <ReferenceInput
            label="Affiliate"
            source="affiliate_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
            sort={{field: "affiliate_id", order: "ASC"}}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Offer"
            source="offer_id"
            reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
            sort={{field: "offer_id", order: "ASC"}}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
            />
          </ReferenceInput>
          <TextInput source="tag_url" validate={[required()]} fullWidth/>
        </SimpleForm>
      </Edit>
    </>
  )
}
