import React, { useState } from 'react'
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import {
  AsideHistory,
  GoBackButton,
} from '../../components'
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  Edit,
  SelectInput,
  useNotify,
  WithRecord,
  NumberInput,
} from 'react-admin'
import { RefreshTuneEntitiesButton } from '../../components/RefreshTuneEntitiesButton'
import { setChoices } from '../../utils/setChoices'
import { cronNames } from '../../config/constants'
import { AlwaysOnSaveButton } from './helpers/AlwaysOnSaveButton'
import { usePermissionCheck } from '../../hooks/usePermissionCheck'
import { ROLES } from '../../config/constants/roles'
import { validatePositiveInteger } from '../../utils/helpers'

export const CronSettingsEdit = props => {
  const [expression, setExpression] = useState('')
  const [toggle, setToggle] = useState(false)
  const notify = useNotify()
  const [cronName, setCronName] = useState(null)
  const isUserWithPermission = usePermissionCheck([ROLES.ADMIN]);

  const onError = () => {
    notify('Something went wrong', {type: 'error'})
  }

  return (
    <>
      <GoBackButton path="/cron-settings"/>
      <Edit {...props} aside={<AsideHistory/>} onFailure={onError} mutationMode="pessimistic">
        <SimpleForm toolbar={<AlwaysOnSaveButton/>}>
          <TextInput source="id" label="ID" disabled={true}/>
          <SelectInput
            source="cron_name"
            label="Cron Name"
            choices={setChoices(cronNames)}
            disabled={true}
            required={true}
          />
          <BooleanInput source="is_enabled" label="Is Enabled"/>
          <WithRecord label="Cron run time" render={record => {
            !expression && setExpression(record.time_expression)
            !cronName && setCronName(record.cron_name)

            return (
               <div className="w-100">
                  <Cron value={expression} setValue={value => {
                    setExpression(value)
                    record.time_expression = value || record.time_expression
                    setToggle(prev => !prev)
                  }
                  }/>
               </div>)
          }}/>
          <TextInput
            source="time_expression"
            label="Time Expression"
            required={true}
            value={expression}
            onChange={({target}) => setExpression(target.value)}
          />
          <NumberInput
            source="current_id"
            label="Current ID"
            disabled={!isUserWithPermission}
            validate={[validatePositiveInteger]}
          /> 
          <NumberInput
            source="last_id"
            label="Last ID"
            disabled={!isUserWithPermission}
            validate={[validatePositiveInteger]}
          />
          {cronName === 'refresh_tune_entities' && (
            <RefreshTuneEntitiesButton style={{width: '220px', margin: '20px 0'}} />
          )}
        </SimpleForm>
      </Edit>
    </>
  )
}
