import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { GoBackButton, AsideHistory, CustomEditToolbar } from '../../components';

export const NotificationsEmailsEdit = props => {
  const { total } = useFetchEntities('brand');

  return (
    <>
      <GoBackButton path="/notification-emails"/>
      <Edit
        aside={<AsideHistory/>}
        {...props}
      >
        <SimpleForm toolbar={<CustomEditToolbar />}>
          <TextInput source="id" disabled={true}/>
          <BooleanInput source="sent_success"/>
          <TextInput source="subject" className="w-50" validate={[required()]}/>
          <TextInput source="template" className="w-50" validate={[required()]}/>
          <TextInput source="context.brand.domain" label="Brand domain" className="w-50" validate={[required()]}/>
          <ReferenceInput
            source="context.brand.id"
            reference="brand"
            sort={{field: "id", order: "ASC"}}
            perPage={total}
          >
            <AutocompleteInput
              label="Brand"
              validate={[required()]}
              optionText={(choice) => `${choice.id} - ${choice.name}`}
              className="w-50"
            />
          </ReferenceInput>
          <TextInput source="context.brand.type" label="Type (write as in table)" validate={[required()]}/>
          <TextInput source="send_time"/>
        </SimpleForm>
      </Edit>
    </>
  )
}



