import { Brand } from "../config/constants";

const isValidDomainTrackingArray = (trackingData) => {
  return trackingData && Array.isArray(trackingData);
};

const domainHasUrl = (domain) => {
  return domain && domain.additional_url;
};

const createDomainTrackingEntry = (domain) => {
  return {
    additional_url: domain.additional_url,
    cron_status: typeof domain.cron_status === 'string' ? domain.cron_status : convertCronStatusToString(domain.cron_status),
  };
};

export const convertCronStatusToString = (cronStatus) =>
  cronStatus || typeof cronStatus === 'undefined' ? 'error' : 'success';

export const processDomainTrackingData = (additionalDomainTracking) => {
  if (!isValidDomainTrackingArray(additionalDomainTracking)) {
    return [];
  }

  return additionalDomainTracking
    .filter((domain) => domainHasUrl(domain))
    .map((domain) => createDomainTrackingEntry(domain));
};

export const getBooleanValue = (record, field) => {
  return typeof record[field] === 'string' ? record[field] === 'error' : record[field];
};

const parseSource = (source) => {
  const match = source.match(/additional_domain_tracking\.(\d+)\.(.+)/);
  return match ? { index: parseInt(match[1], 10), field: match[2] } : { index: -1, field: null };
};

const updateSingleDomainTracking = ({ items, index, field, value }) => {
  const updatedItems = [...items];
  const currentItem = updatedItems[index] || {};
  updatedItems[index] = { ...currentItem, [field]: value };
  return updatedItems;
};

const ensureAllItemsAreObjects = (items) => items.map(item => item || {});

const updateDomainTracking = ({ index, field, value, setAdditionalDomainTracking, setCopiedRecord }) => {
  setAdditionalDomainTracking(items => 
    updateSingleDomainTracking({ items, index, field, value })
  );

  if (setCopiedRecord) {
    setCopiedRecord(prev => ({
      ...prev,
      additional_domain_tracking: updateSingleDomainTracking({
        items: prev.additional_domain_tracking ? ensureAllItemsAreObjects(prev.additional_domain_tracking) : [], 
        index,
        field,
        value
      })
    }));
  }
};

export const handleDomainTrackingChange = ({ source, value, setAdditionalDomainTracking, setCopiedRecord }) => {
  const { index, field } = parseSource(source);

  if (index !== -1 && field != null) {
    updateDomainTracking({ index, field, value, setAdditionalDomainTracking, setCopiedRecord });
  }
};

export const validateAdditionalUrl = ({ value, domain }) => {
  if (value && value.toLowerCase() === domain.toLowerCase()) {
    return 'Additional Domain must not be equal to Main Domain';
  }

  return undefined;
};

export const extractIndexFromClassName = (className) => {
  const match = className.match(/button-remove-additional_domain_tracking-(\d+)/);
  return match ? parseInt(match[1], 10) : -1;
};

export const handleRemoveDomainTrackingEntry = ({ indexToRemove, setAdditionalDomainTracking, setCopiedRecord }) => {
  if (setAdditionalDomainTracking) {
    setAdditionalDomainTracking(currentItems => 
      currentItems.filter((_, index) => index !== indexToRemove)
    );
  }

  if (setCopiedRecord) {
    setCopiedRecord(existingRecord => {
      const updatedEntries = existingRecord?.additional_domain_tracking.filter((_, index) => index !== indexToRemove);
      return { ...existingRecord, additional_domain_tracking: updatedEntries };
    });
  }
};

export const setParsedPluginInfo = (pluginInfo, setPluginInfo) => {
  try {
    const parsedPluginInfo = typeof pluginInfo === 'string' ? JSON.parse(pluginInfo) : pluginInfo;
    setPluginInfo(parsedPluginInfo || {});
  } catch (error) {
    setPluginInfo({});
    console.error("Error parsing plugin info:", error);
  }
};

export const removeCronStatus = (pluginInfo) => {
  const { cron_status, ...rest } = typeof pluginInfo === 'string'
    ? JSON.parse(pluginInfo)
    : pluginInfo;

  return rest;
};

export const isDomainTrackingDisabled = (integrationType) => {
  return integrationType.toLowerCase() === Brand.integrationTypes.shopify.toLowerCase()
    || integrationType.toLowerCase() === Brand.integrationTypes.bigcommerce.toLowerCase();
};
