import { Link } from 'react-router-dom';

export const parseStringToNumbersArray = (input) => {
  return input?.length > 0
    ? input.split(',').map(item => Number(item.trim()))
    : null;
};

export const validateDecimal = (value) => {
  if (!value) return;

  const decimalRegex = /^[0-9]{1,8}(\.[0-9]{1,2})?$/;
  return decimalRegex.test(value) ? null : 'Invalid format. Maximum 10 digits. 2 digits after decimal point allowed.';
};

export const validateFilters = (value) => {
  if (!value) return undefined;

  const isStringOrNumber = (val) => typeof val === 'string' || typeof val === 'number';
  
  let parsedValue;

  if (typeof value === "string") {
    try {
      parsedValue = JSON.parse(value);
    } catch (error) {
      return 'Invalid format. Expected format: {"key": value,...}';
    }
  } else if (typeof value === "object" && value !== null) {
    parsedValue = value;
  } else {
    return 'Invalid format. Expected format: {"key": value,...}';
  }

  for (let key in parsedValue) {
    if (typeof key !== 'string' || !isStringOrNumber(parsedValue[key])) {
      return 'Invalid format. Expected format: {"key": value,...}';
    }
  }

  return undefined;
};

export const getRowWithLink = (redirect, entityId, entities) => {
  const entity = entities.find(e => e.id === entityId);

  if (!entity) return (
    <span>
      {entityId}
    </span>
  );

  return (
     <span>
        {entity.id}-<Link
           to={`/${redirect}/${entity.id}`}
           onClick={e => e.stopPropagation()}
           style={{ color: 'rgb(79, 60, 201)' }}
        >
           {entity.name}
        </Link>
     </span>
  );
};

export const isArrayFieldEmpty = field => !field || (Array.isArray(field) && field.length === 0);

export const transformAffiliatesArray = async (data) => {
  if (typeof data.affiliate_id === 'string') {
    data.affiliate_id = parseStringToNumbersArray(data.affiliate_id);
  } else if (isArrayFieldEmpty(data.affiliate_id)) {
    data.affiliate_id = null;
  }

  return data;
};

export const transformArrayField = (field) => {
  if (Array.isArray(field)) {
     return field.length > 0 ? field : null;
  } else {
     return parseStringToNumbersArray(field);
  }
};

export const convertStringToArray = (field) => {
  if (!field) {
    return [];
  } else if (typeof field === 'string') {
    return field.split(',').map(item => item.trim());
  }
  return field;
};

export const convertToBoolean = (value) => {
  if (value === null) {
    return '';
  }
  return value === 1 ? 'True' : 'False';
};

const buildQueryParams = (params) => {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    const formattedValue = typeof value === 'object' ? JSON.stringify(value) : value;
    searchParams.append(key, formattedValue);
  }
  return searchParams.toString();
};

export const copyLocalStorageValues = ({ sourceKey, targetKey, entityId }) => {
  const sourceValue = localStorage.getItem(sourceKey);
  let params = sourceValue ? JSON.parse(sourceValue) : {};

  if (entityId) {
    const entityParams = JSON.parse(entityId);
    params.filter = { ...params.filter, ...entityParams };
  }

  if (params.sort === "count") {
    params.sort = "id";
  }

  localStorage.setItem(targetKey, JSON.stringify(params));

  const queryParams = buildQueryParams(params);
  return `?${queryParams}`;
};

export const getFilterFromSearchParams = (search) => {
  const searchParams = new URLSearchParams(search);
  const filterParam = searchParams.get('filter');

  return filterParam ? JSON.parse(filterParam) : null;
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const validatePositiveInteger = (value) => {
  if (value && (!Number.isInteger(value) || value < 0)) {
    return 'Only positive integers are allowed';
  }
};
