import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useRedirectFromParams = (entity, redirectLink) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filter = params.get('filter');
    if (filter && JSON.parse(filter)[entity]) {
      navigate(`/${redirectLink}?filter=${filter}`);
    }
  }, [location.search, navigate, entity, redirectLink]);
};
