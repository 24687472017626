import { aggregatedTablesPaths, tableMenuItems } from '../config/constants';

export const checkValueIsValid = (options, value) => options.some(option => option.value === value);

export const useAggregateValue = (tableName) => {
  const localStorageValue = localStorage.getItem('aggregate');
  const isValueValid = checkValueIsValid(tableMenuItems[tableName].options, localStorageValue);
  return isValueValid ? localStorageValue : tableMenuItems[tableName].defaultValue;
};

export const setLocalStorageAggregate = (selectedValue) => {
  localStorage.setItem('aggregate', selectedValue);
};

export const navigateToAggregatePath = (navigate, tableName, selectedValue) => {
  const path = aggregatedTablesPaths[tableName][selectedValue] || tableName;
  navigate(`/${path}`);
};

export const handleAggregateChange = (navigate, tableName) => (event) => {
  const selectedValue = event.target.value;
  setLocalStorageAggregate(selectedValue);
  navigateToAggregatePath(navigate, tableName, selectedValue);
};

export const compareAggregateValue = (aggregateValue) => {
  return localStorage.getItem('aggregate') !== aggregateValue;
};
