import React, { useEffect, useState } from 'react'
import {
  Create,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRemoveFromStore,
  useStore,
} from 'react-admin'
import { GoBackButton, ToolbarAlwaysOnSaveButton } from '../../components'
import { rolesChoices } from './helpers/constants'

export const UsersCreate = props => {
  const [copiedRecord, setCopiedRecord] = useState('')
  const [copiedRecordTemp] = useStore('users.record_copy', null)

  const remove = useRemoveFromStore()

  useEffect(() => {
    setCopiedRecord(copiedRecordTemp)
    remove('users.record_copy')
  }, [])

  return (
    <>
      <GoBackButton path="/users"/>
      <Create {...props} record={copiedRecord || null}>
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
          <TextInput source="username" className="w-50" validate={[required()]} />
          <TextInput source="password" className="w-50" validate={[required()]} />
          <SelectInput source="role" choices={rolesChoices} />
        </SimpleForm>
      </Create>
    </>
  )
}
