import { useState } from 'react';
import { useNotify, useRefresh, useUnselectAll, useDeleteMany, useRedirect } from 'react-admin';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export const DeleteConfirmationButton = ({ resource, selectedIds }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const unselectAll = useUnselectAll(resource);
  const [deleteMany, { loading }] = useDeleteMany();

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    deleteMany(resource, { ids: selectedIds })
      .then(() => {
        notify('Items deleted successfully', 'info');
        handleClose();
        refresh();
        unselectAll();
        redirect(`/${resource}`);
      })
      .catch((error) => {
        notify('Error: items not deleted', 'warning');
        console.error('Delete operation failed:', error);
      });
  };
  

  return (
    <>
      <Button
        size="small"
        onClick={handleOpen}
        disabled={loading}
        startIcon={<DeleteIcon />}
        style={{ color: 'rgb(211, 47, 47)' }}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the selected records?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDelete}
            color="primary"
            autoFocus
            disabled={loading}
          >
            Confirm
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
