import {
  BooleanInput,
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  number,
  required,
} from 'react-admin';
import { setChoices } from '../../utils';
import { BlockEventSettings } from '../../config/constants';
import { ActionButton, ToolbarAlwaysOnSaveButton } from '../../components';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';

export const BlockEventSettingCreate = props => {
  const { total } = useFetchEntities('brand');

  return (
    <>
      <ActionButton type="back" />
      <Create {...props} record={useProcessCopiedRecord('block-event-settings') || null}>
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton localStorageValue='block_event_settings_list' />}>
          <SelectInput source="type" choices={setChoices(BlockEventSettings.types)} validate={[required()]}/>
          <ReferenceInput
            label="Brand"
            source="brand_id"
            reference="brand"
            sort={{field: "id", order: "ASC"}}
            perPage={total}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.id} - ${choice.name}`}
            />
          </ReferenceInput>
          <TextInput source="transaction_id" validate={[number()]}/>
          <TextInput source="visit_id" validate={[number()]}/>
          <TextInput source="customer_id" validate={[number()]}/>
          <TextInput source="customer_email" className="w-50"/>
          <TextInput source="customer_ip" className="w-50"/>
          <BooleanInput source="hashed_email_check"/>
          <BooleanInput source="zero_sale_amount"/>
        </SimpleForm>
      </Create>
    </>
  )
}
