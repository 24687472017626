import * as React from 'react'
import {
  Edit,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { GoBackButton, CustomEditToolbar } from '../../components'
import { rolesChoices } from './helpers/constants'

export const UsersEdit = props => (
  <>
    <GoBackButton path="/users"/>
    <Edit {...props}>
      <SimpleForm toolbar={<CustomEditToolbar />}>
        <TextInput source="username" className="w-50" validate={[required()]} />
        <TextInput source="password" className="w-50" validate={[required()]} />
        <SelectInput source="role" choices={rolesChoices} />
      </SimpleForm>
    </Edit>
  </>
)
