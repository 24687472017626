import { useRecordContext, FunctionField } from 'react-admin';
import { getRowWithLink } from '../utils/helpers';
import { useFetchEntities } from '../hooks/useFetchEntities';

export const ContextParser = props => {
  const {source, field} = props;
  const record = useRecordContext(props);
  const { entities: brands } = useFetchEntities('brand')

  return (
    field === 'id' ?
      <FunctionField
        source="context.brand.id"
        label="Brand"
        render={record => getRowWithLink('brand', record.context?.brand?.id, brands)}
      /> 
      :
      <span>{record[source].brand[field]}</span>
  );
};
