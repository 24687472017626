import {
   List,
   TextField,
   UrlField,
   BooleanField,
   DatagridConfigurable,
   FunctionField,
} from 'react-admin'
import {
   RowsPerPage,
   FixTypeNames,
   DateInOneRow,
   EntityIdWithName,
   CustomArrayField,
} from '../../components'
import { Brand } from '../../config/constants';
import { useRowStyle } from '../../hooks/useRowStyle'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { BulkActionCopy } from '../../components/BulkActionCopy'
import { getBrandFilters } from '../../utils/filters/brandFilters'
import { getRowWithLink } from '../../utils/helpers'

export const BrandList = props => {
   const rowClassName = useRowStyle()
   const { entities: brands, total: brandsCount } = useFetchEntities('brand')

   return (
      <List 
         {...props}
         perPage={50}
         pagination={<RowsPerPage/>}
         filters={getBrandFilters(brandsCount)}
         className="list-admin-wrapper"
         actions={<ToolBarRowStyleToggle showSelectColumnsButton />}
      >
         <div className="datagrid-wrapper">
            <DatagridConfigurable
               rowClick="edit"
               className={`${rowClassName} datagrid-content`}
               bulkActionButtons={<BulkActionCopy />}
               omit={Brand.defaultOffColumns}
            >
               <TextField source="id" label="ID"/>
               <TextField source="name" label="Brand"/>
               <FixTypeNames source="type" label="Integration Type"/>
               <UrlField source="home_url" label="Home URL" />
               <BooleanField source="is_allow_track" label="Tracking Status" />
               <TextField source="plugin_status" label="Plugin status"/>
               <BooleanField source="plugin_site_key_match" label="Plugin Site Key match"/>
               <DateInOneRow source="update_time" label="Update time" showTime={true}/>
               <FunctionField
                  source="connected_brand_id"
                  label="Connected Brand ID"
                  render={record => getRowWithLink('brand', record.connected_brand_id, brands)}
               />
               <EntityIdWithName
                  source="connect_brands_customers"
                  label="Connect Brands Customers"
                  reference="brand"
                  targetSource="name"
               />
               <UrlField source="domain" />
               <TextField source="site_key" />
               <TextField source="site_secret" />
               <BooleanField source="plugin_site_secret_match" label="Plugin Site Secret match" />
               <BooleanField source="calc_tax" />
               <BooleanField source="calc_shipping" />
               <FunctionField
                  source="plugin_info"
                  render={record => (
                     <div style={{ width: '300px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        {record.plugin_info}
                     </div>
                  )}
               />
               <TextField source="multiple_by" />
               <CustomArrayField source="integration_func" />
               <BooleanField source="has_iframe_tags" />
               <TextField source="conflict_type" />
               <CustomArrayField source="conflict_systems_ids" />
               <CustomArrayField source="unique_conflict_cookies" />
               <CustomArrayField
                  source="additional_domain_tracking"
                  renderArrayItem={item => `${item.additional_url} (${item.cron_status})`}
               />
            </DatagridConfigurable>
         </div>
      </List>
   )
}
