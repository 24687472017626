import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { ActionButton, ToolbarAlwaysOnSaveButton } from '../../components';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';
import { useFetchEntities } from '../../hooks/useFetchEntities';

export const NotificationsEmailsCreate = props => {
  const { total } = useFetchEntities('brand');

  return (
    <>
      <ActionButton type="back" />
      <Create {...props} record={useProcessCopiedRecord('notification-emails') || null}>
        <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton localStorageValue='notification_emails_list' />}>
          <BooleanInput source="sent_success" label="Sent success"/>
          <TextInput source="subject" validate={[required()]} label="Subject"/>
          <TextInput source="template" validate={[required()]} label="Template"/>
          <TextInput source="context.brand.domain" label="Brand domain" className="w-50" validate={[required()]}/>
          <ReferenceInput
            source="context.brand.id"
            reference="brand"
            sort={{ field: "id", order: "ASC" }}
            perPage={total}
          >
            <AutocompleteInput
              label="Brand"
              validate={[required()]}
              optionText={(choice) => `${choice.id} - ${choice.name}`}
              className="w-50"
            />
          </ReferenceInput>
          <TextInput source="context.brand.type" label="Type (write as in table)" validate={[required()]}/>
          <TextInput source="send_time" label="Send time"/>
        </SimpleForm>
      </Create>
    </>
  )
}
