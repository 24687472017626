import {
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { setChoices } from '../setChoices';
import { Goals } from '../../config/constants';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const goalsFilters = [
  <TextInput
    source="search"
    label="Offer ID, Postback URL"
    className="search-input-width"
    alwaysOn
  />,
  <SelectInput source="event_type" choices={setChoices(Goals.eventType)} />,
  <ReferenceInput
    label="Affilate"
    source="affiliate_id"
    reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
    sort={{ field: 'affiliate_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.affiliate_name}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Offer"
    source="offer_id"
    reference={REQUESTS_ROUTES.GET_ALL_OFFERS}
    sort={{ field: 'offer_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-md"
      optionText={(choice) => choice.offer_name}
    />
  </ReferenceInput>,
  <TextInput
    source="postback_url"
    label="Postback URL"
    className="search-input-width"
  />,
];

export const goalsAggregatedFilters = [
  <TextInput
    source="search"
    label="Count, Offer ID, Update Time"
    className="search-input-width"
    alwaysOn
  />,
  <ReferenceInput
    label="Affilate"
    source="affiliate_id"
    reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
    sort={{ field: 'affiliate_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.affiliate_name}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Offer"
    source="offer_id"
    reference={REQUESTS_ROUTES.GET_ALL_OFFERS}
    sort={{ field: 'offer_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-md"
      optionText={(choice) => choice.offer_name}
    />
  </ReferenceInput>,
];
