import {
   List,
   Datagrid,
   TextField,
   FunctionField,
} from 'react-admin'
import {
   RowsPerPage,
   EntityIdWithName,
   DateInOneRow,
   BulkActionCopy,
} from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { getRowWithLink } from '../../utils/helpers'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { getAffPrioritiesFilters } from '../../utils/filters/affPrioritiesFilters'
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const AffPrioritiesList = props => {
   const rowClassName = useRowStyle()
   const { entities: brands, total: brandsCount } = useFetchEntities('brand')
   
   return (
      <List
         {...props}
         pagination={<RowsPerPage/>}
         filters={getAffPrioritiesFilters(brandsCount)}
         className="list-admin-wrapper"
         actions={<ToolBarRowStyleToggle/>}
      >
         <Datagrid rowClick="edit" className={rowClassName} bulkActionButtons={<BulkActionCopy/>}>
            <TextField source="id"/>
            <FunctionField
               source="brand_id"
               label="Brand"
               render={record => getRowWithLink('brand', record.brand_id, brands)}
            />
            <EntityIdWithName
               source="affilate_id"
               reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
               targetSource="affiliate_name"
            />
            <TextField source="priority"/>
            <DateInOneRow source="update_time" label="Update time" showTime={true}/>
         </Datagrid>
      </List>
   )
}
