import {
   TopToolbar,
   FilterButton,
   CreateButton,
   ExportButton,
   useTheme,
   SelectColumnsButton,
} from 'react-admin'
import { Button, Tooltip } from '@mui/material'
import { darkTheme, lightTheme } from '../Layout/themes'
import { ReorderRounded } from '@mui/icons-material'
import { RefreshTuneEntitiesButton } from './RefreshTuneEntitiesButton'
import { AggregateBySelect } from './AggregateBySelect'
import { ActionButton } from './ActionButton'
import { DownloadButton } from './DownloadButton'

export const ToolBarRowStyleToggle = ({
   showRefreshEntitiesButton = false,
   showSelectColumnsButton = false,
   tableName = '',
   createButtonUrl = '',
   downloadEndpoint = '',
   downloadLabel = 'Download'
}) => {
   const [theme, setTheme] = useTheme()

   return (
      <TopToolbar>
         {downloadEndpoint && <DownloadButton downloadEndpoint={downloadEndpoint} label={downloadLabel} />}
         {showRefreshEntitiesButton &&
            <RefreshTuneEntitiesButton style={{minWidth: '226px', fontSize: '12px'}} label="Refresh Tune Entities" />
         }
         {tableName && <AggregateBySelect tableName={tableName} />}
         <Button
            onClick={() => {
               const value = !localStorage.getItem('RaStore.isRowStyleActive') ? '1' : ''
               localStorage.setItem('RaStore.isRowStyleActive', value)
               if (theme.palette.mode === 'dark') {
                  setTheme({...darkTheme, isRowStyleActive: value})
               } else {
                  setTheme({...lightTheme, isRowStyleActive: value})
               }

            }}
         >
            <Tooltip title="Color every second row" placement="bottom">
               <ReorderRounded color="action" fontSize="small"/>
            </Tooltip>
         </Button>
         {showSelectColumnsButton && <SelectColumnsButton />}
         <FilterButton/>
         {createButtonUrl
            ? <ActionButton type="create" url={createButtonUrl} />
            : <CreateButton />
         }
         <ExportButton/>
      </TopToolbar>
   )
}