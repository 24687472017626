import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button as MuiButton,
  CircularProgress,
} from '@mui/material';

const ConfirmationDialog = ({
  open,
  handleConfirm,
  handleCancel,
  numberOfRows,
  numberOfDuplicates,
  selectedBrand,
  loading,
}) => {
  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>Confirm Upload</DialogTitle>
      <DialogContent sx={{ height: '210px' }}>
        {numberOfDuplicates > 0 && (
          <Typography gutterBottom>
            Ignored {numberOfDuplicates} duplicates from the .csv file.
          </Typography>
        )}
        <Typography>
          Are you sure you want to upload {numberOfRows} rows to the
          'brand_hashed_emails' table under the Brand {selectedBrand?.name}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <MuiButton
          onClick={handleConfirm}
          color="primary"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Processing...' : 'Confirm'}
        </MuiButton>
        <MuiButton onClick={handleCancel} color="primary" disabled={loading}>
          Cancel
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
