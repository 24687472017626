import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import {
  RowsPerPage,
  DateInOneRow,
  EntityIdWithName,
  BulkActionCopy,
  ToolBarRowStyleToggle,
} from '../../components';
import { useRowStyle } from '../../hooks/useRowStyle';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { getRowWithLink } from '../../utils/helpers';
import { PostbackSettings } from '../../config/constants';
import { setChoices } from '../../utils';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const PostbackSettingsList = (props) => {
  const rowClassName = useRowStyle();
  const { entities: brands, total } = useFetchEntities('brand');

  const postbackSettingsFilters = [
    <TextInput
      source="search"
      label="Brand ID, Offer ID, Affiliate ID"
      alwaysOn
      className="search-input-width"
    />,
    <ReferenceInput
      label="Brand"
      source="brand_id"
      reference="brand"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={total}
    >
      <AutocompleteInput
        className="search-input-width"
        optionText={(choice) => choice.name}
      />
    </ReferenceInput>,
    <ReferenceInput
      label="Affilate"
      source="affiliate_id"
      reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
      sort={{ field: 'affiliate_name', order: 'ASC' }}
    >
      <AutocompleteInput
        className="search-input-width"
        optionText={(choice) => choice.affiliate_name}
      />
    </ReferenceInput>,
    <ReferenceInput
      label="Offer"
      source="offer_id"
      reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
      sort={{ field: 'offer_name', order: 'ASC' }}
    >
      <AutocompleteInput
        className="search-input-md"
        optionText={(choice) => choice.offer_name}
      />
    </ReferenceInput>,
    <SelectInput source="postback_sub2" choices={setChoices(PostbackSettings.postbackSub2)}/>,
    <SelectInput source="postback_sub3" choices={setChoices(PostbackSettings.postbackSub3)}/>,
    <SelectInput source="postback_sub4" choices={setChoices(PostbackSettings.postbackSub4)}/>,
    <SelectInput source="postback_sub5" choices={setChoices(PostbackSettings.postbackSub5)}/>,
  ];

  return (
    <List
      {...props}
      perPage={25}
      pagination={<RowsPerPage />}
      filters={postbackSettingsFilters}
      className="list-admin-wrapper"
      actions={<ToolBarRowStyleToggle />}
    >
      <Datagrid
        rowClick="edit"
        className={rowClassName}
        bulkActionButtons={<BulkActionCopy />}
      >
        <TextField source="id" label="ID" />
        <FunctionField
          source="brand_id"
          label="Brand"
          render={(record) => getRowWithLink('brand', record.brand_id, brands)}
        />
        <EntityIdWithName
          source="offer_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
          targetSource="offer_name"
        />
        <EntityIdWithName
          source="affiliate_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
          targetSource="affiliate_name"
        />
        <TextField source="postback_sub2" label="Postback Sub2" />
        <TextField source="postback_sub3" label="Postback Sub3" />
        <TextField source="postback_sub4" label="Postback Sub4" />
        <TextField source="postback_sub5" label="Postback Sub5" />
        <DateInOneRow source="create_time" label="Create time" showTime />
        <DateInOneRow source="update_time" label="Update time" showTime />
      </Datagrid>
    </List>
  );
};
