import { useTheme } from 'react-admin'

export const useRowStyle = () => {
   const [theme] = useTheme()
   const isDarkMode = theme?.palette.mode === 'dark'
   const isActive = localStorage.getItem('RaStore.isRowStyleActive')

   const rowClassName = isDarkMode ? 'table-dark-theme' : 'table-light-theme'

   return isActive ? rowClassName : ''
}