import { Edit, TabbedForm, FormTab } from 'react-admin';
import { GoBackButton } from '../../components';
import AffiliatesTable from './components/AffiliatesTable';
import OfferMetadata from './components/OfferMetadata';
import OfferTitle from './components/OfferTitle';

export const OffersManagementEdit = props => {
  return (
    <>
      <GoBackButton path="/offers" />
      <Edit {...props} title={<OfferTitle />}>
        <TabbedForm toolbar={false}>
          <FormTab label="Main">
            <OfferMetadata />
          </FormTab>
          <FormTab label="Partners">
            <AffiliatesTable />
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  );
};

export default OffersManagementEdit;
