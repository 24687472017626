import * as React from 'react'
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  number,
  required,
} from 'react-admin'
import { BlockEventSettings } from '../../config/constants'
import { setChoices } from '../../utils'
import { AsideHistory, GoBackButton, CustomEditToolbar } from '../../components'
import { useFetchEntities } from '../../hooks/useFetchEntities'

export const BlockEventSettingEdit = props => {
  const { total } = useFetchEntities('brand')

  return (
    <>
      <GoBackButton path="/block-event-settings"/>
      <Edit
        aside={<AsideHistory/>}
        {...props}
      >
        <SimpleForm toolbar={<CustomEditToolbar />}>
          <SelectInput source="type" choices={setChoices(BlockEventSettings.types)} validate={[required()]}/>
          <ReferenceInput
            label="Brand"
            source="brand_id"
            reference="brand"
            sort={{field: "id", order: "ASC"}}
            perPage={total}
          >
            <AutocompleteInput
              className="search-input-md"
              optionText={(choice) => `${choice.id} - ${choice.name}`}
            />
          </ReferenceInput>
          <TextInput label="Transaction ID" source="transaction_id" validate={[number()]}/>
          <TextInput label="Visit ID" source="visit_id" validate={[number()]}/>
          <TextInput label="Customer ID" source="customer_id" validate={[number()]}/>
          <TextInput source="customer_email" className="w-50"/>
          <TextInput source="customer_ip" className="w-50"/>
          <BooleanInput source="hashed_email_check"/>
          <BooleanInput source="zero_sale_amount" label="Zero Sale Amount Check"/>
        </SimpleForm>
      </Edit>
    </>
  )
}
