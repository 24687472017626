import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  number,
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin'
import { AsideHistory, GoBackButton, CustomEditToolbar } from '../../components'
import { setChoices } from '../../utils/setChoices'
import { PreApprovalSettings } from '../../config/constants'
import { validateDecimal } from '../../utils/helpers'
import {
  preApprovalSettingsCheckers,
  preApprovalSettingsStatuses,
  preApprovalSettingsCommission,
} from '../../config/constants'
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const PreApprovalSettingsEdit = props => (
  <>
    <GoBackButton path="/pre-approval-settings"/>
    <Edit {...props} aside={<AsideHistory/>}>
      <SimpleForm toolbar={<CustomEditToolbar />}>
        <TextInput source="id" disabled={true} />
        <ReferenceInput
          label="Affiliate"
          source="affiliate_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
          sort={{field: "affiliate_id", order: "ASC"}}
        >
          <AutocompleteInput
            className="search-input-md"
            optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Offer"
          source="offer_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
          sort={{field: "offer_id", order: "ASC"}}
        >
          <AutocompleteInput
            className="search-input-md"
            optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
          />
        </ReferenceInput>
        <SelectInput
          source="checker"
          label="Checker"
          choices={setChoices(preApprovalSettingsCheckers)}
        />
        <TextInput source="above_sale_amount" validate={validateDecimal} />
        <TextInput source="below_sale_amount" validate={validateDecimal} />
        <SelectInput source="result" choices={setChoices(PreApprovalSettings.decisionResult)}/>
        <SelectInput
          source="status"
          label="Status"
          choices={setChoices(preApprovalSettingsStatuses)}
          validate={[required()]}
        />
        <SelectInput
          source="fallback_result"
          choices={setChoices(PreApprovalSettings.decisionFallbackResult)}
        />
        <SelectInput
          source="commission_structure"
          choices={setChoices(preApprovalSettingsCommission)}
          style={{ minWidth: '215px'}}
        />
        <TextInput source="untracked_period" validate={[number()]} />
      </SimpleForm>
    </Edit>
  </>
)
