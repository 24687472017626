import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export const getAffiliateColumns = () => [
  { field: 'affiliate_id', headerName: 'Affiliate ID', flex: 1 },
  { field: 'affiliate_name', headerName: 'Affiliate', flex: 1 },
  { field: 'aff_type_coupons', headerName: 'Aff Type Coupons', flex: 1 },
  {
    field: 'approved',
    headerName: 'Approved',
    flex: 1,
    renderCell: (params) =>
      params.value ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
  },
  {
    field: 'unapproved',
    headerName: 'Unapproved',
    flex: 1,
    renderCell: (params) =>
      params.value ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
  },
  {
    field: 'blocked',
    headerName: 'Blocked',
    flex: 1,
    renderCell: (params) =>
      params.value ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
  },
];
