import {
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { setChoices } from '../setChoices';
import { orderApprovalDecisions } from '../../config/constants';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const getOrderApprovalFilters = (total) => [
  <TextInput
    source="search"
    label="ID, Brand ID, Brand Name, Affiliate ID, Offer ID"
    className="search-input-width"
    alwaysOn
  />,
  <ReferenceInput
    label="Brand"
    source="brand_id"
    reference="brand"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={total}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.name}
    />
  </ReferenceInput>,
  <TextInput source="event_id" label="Event ID" />,
  <SelectInput
    source="decision"
    label="Decision"
    choices={setChoices(orderApprovalDecisions)}
  />,
  <TextInput source="order_id" label="Order ID" />,
  <ReferenceInput
    label="Affilate"
    source="affilate_id"
    reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
    sort={{ field: 'affiliate_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-width"
      optionText={(choice) => choice.affiliate_name}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Offer"
    source="offer_id"
    reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
    sort={{ field: 'offer_name', order: 'ASC' }}
  >
    <AutocompleteInput
      className="search-input-md"
      optionText={(choice) => choice.offer_name}
    />
  </ReferenceInput>,
];

export const getOrderApprovalAggregatedFilters = (aggregateValue, total) => [
  <TextInput
    source="search"
    label={`Count, ${
      aggregateValue === 'affilate_id' ? 'Affiliate ID' : 'Brand ID'
    }, Update Time`}
    className="search-input-width"
    alwaysOn
  />,
  aggregateValue === 'affilate_id' ? (
    <ReferenceInput
      label="Affilate"
      source="affilate_id"
      reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
      sort={{ field: 'affiliate_name', order: 'ASC' }}
    >
      <AutocompleteInput
        className="search-input-width"
        optionText={(choice) => choice.affiliate_name}
      />
    </ReferenceInput>
  ) : (
    <ReferenceInput
      label="Brand"
      source="brand_id"
      reference="brand"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={total}
    >
      <AutocompleteInput
        className="search-input-width"
        optionText={(choice) => choice.name}
      />
    </ReferenceInput>
  ),
];
