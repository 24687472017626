import { apiUrl, httpClient } from '../../config/http-client';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const fetchTuneUpdateInfo = async () => {
  try {
    const { json } = await httpClient(`${apiUrl}/${REQUESTS_ROUTES.LAST_UPDATE_TUNE_TIME}`, {
      method: 'GET',
    });

    return {
      lastUpdateTime: json.last_update_tune_time,
      lastUpdateStatus: json.last_update_status,
    };
  } catch (error) {
    console.error('Error fetching Tune update info:', error);
    throw error;
  }
};

export const handleRefreshTuneEntities = async () => {
  try {
    const { json } = await httpClient(`${apiUrl}/${REQUESTS_ROUTES.REFRESH_TUNE_ENTITIES}`, {
      method: 'POST',
    });
    return json;
  } catch (error) {
    console.error('Error refreshing Tune entities:', error);
    throw error;
  }
}; 

export const callAffiliateActionAPI = async ({ actionType, offerId, selectedAffiliates }) => {
  try {
    const response = await httpClient(`${apiUrl}/active-affiliates/${actionType}`, {
      method: 'POST',
      body: JSON.stringify({ offerId, affiliates: selectedAffiliates }),
    });

    if (response.status === 200) {
      return true;
    } else {
      console.error('Failed to process affiliates:', response.json.message);
      return false;
    }
  } catch (error) {
    console.error('Error processing affiliates:', error);
    return false;
  }
};

export const fetchPaginatedAffiliates = async ({
  queryParams,
}) => {
  const url = `${apiUrl}/${REQUESTS_ROUTES.GET_PAGINATED_ACTIVE_AFFILIATES}${queryParams}`;

  try {
    const response = await httpClient(url, { method: 'GET' });
    return {
      affiliates: response.json.affiliates,
      totalAffiliates: response.json.total,
    };
  } catch (error) {
    console.error('Error fetching affiliates:', error);
    throw error;
  }
};
