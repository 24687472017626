import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { buttonProps } from '../config/constants';

export const ActionButton = ({ type, url }) => {
  const navigate = useNavigate();
  const { label, color, buttonStyle, variant, startIcon } = buttonProps[type];

  const handleClick = () => {
    if (type === 'create') {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  return (
    <Button
      onClick={handleClick}
      startIcon={startIcon}
      style={buttonStyle}
      variant={variant}
      color={color}
    >
      {label}
    </Button>
  );
};
