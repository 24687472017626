import { List, Datagrid, TextField } from 'react-admin';
import {
  RowsPerPage,
  EntityIdWithName,
  DateInOneRow,
  BulkActionCopy,
} from '../../components';
import { useRowStyle } from '../../hooks/useRowStyle';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle';
import { getAffApiSettingsFilters } from '../../utils/filters/affApiSettingsFilters';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const AffiliateApiSettingList = (props) => {
  const rowClassName = useRowStyle();
  const { total: brandsCount } = useFetchEntities('brand');

  return (
    <List
      {...props}
      pagination={<RowsPerPage />}
      filters={getAffApiSettingsFilters(brandsCount)}
      className="list-admin-wrapper"
      actions={<ToolBarRowStyleToggle
        downloadEndpoint="documentation/affiliate-api-settings"
        downloadLabel="API Affiliate Documentation"
      />}
    >
      <Datagrid
        rowClick="edit"
        className={rowClassName}
        bulkActionButtons={<BulkActionCopy />}
      >
        <TextField source="id" label="ID" />
        <EntityIdWithName
          source="affiliate_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
          targetSource="affiliate_name"
          label="Affiliate"
        />
        <TextField source="api_key" label="API Key" />
        <EntityIdWithName
          source="offers_limit"
          reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
          targetSource="offer_name"
          label="Offers Limit"
        />
        <EntityIdWithName
          source="brands_limit"
          reference="brand"
          targetSource="name"
          label="Brands Limit"
        />
        <DateInOneRow
          source="create_time"
          label="Create Time"
          showTime={true}
        />
        <DateInOneRow
          source="update_time"
          label="Update Time"
          showTime={true}
        />
      </Datagrid>
    </List>
  );
};
