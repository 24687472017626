import simpleRestProvider from 'ra-data-simple-rest';
import { apiUrl, httpClient } from './http-client';

const baseDataProvider = simpleRestProvider(apiUrl, httpClient);

const customDataProvider = {
  ...baseDataProvider,
  getList: (resource, params) => {
    const resourceMap = {
      'block-hashed-emails-aggregated': 'block-hashed-emails',
      'order-approval-aggregated-by-brand': 'order-approval-brand',
      'order-approval-aggregated-by-affiliate': 'order-approval-affiliate',
      'goals-aggregated': 'goals',
      'notification-emails-aggregated': 'notification-emails',
      'block-event-settings-aggregated': 'block-event-settings',
      'pre-approval-settings-aggregated-by-affiliate': 'pre-approval-settings-affiliate',
      'pre-approval-settings-aggregated-by-offer': 'pre-approval-settings-offer',
      // 'notifications-aggregated': 'notifications',
    };

    const table = resourceMap[resource];
    const endpoint = table ? 'aggregate-table/aggregated' : resource;
    const updatedParams = {
      ...params,
      filter: {
        ...(params.filter || {}),
        table,
      },
    };

    return baseDataProvider.getList(endpoint, updatedParams)
      .then(response => {
        if (resource === 'notification-emails-aggregated') {
          return {
            ...response,
            data: response.data.map(record => ({
              ...record,
              context: typeof record.context === 'string'
                ? JSON.parse(record.context)
                : record.context,
            })),
          };
        }
        
        return response;
      });
  },
};

export const dataProvider = customDataProvider;
