import { usePermissions } from 'react-admin';

export const usePermissionCheck = (allowedRoles) => {
  const { permissions: userRole } = usePermissions();

  if (!allowedRoles || allowedRoles.length === 0) {
    console.error('usePermissionCheck: No roles provided for permissions check.');
    return false;
  }

  return allowedRoles.includes(parseInt(userRole));
};
