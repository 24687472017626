import { useRecordContext } from 'react-admin';

const OfferTitle = () => {
  const record = useRecordContext();

  if (!record) return '';
  return `Offer #${record.offer_id} - ${record.offer_name}`;
};

export default OfferTitle;
