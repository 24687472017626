import { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { formatToUtc } from '../utils/dateUtils';
import { fetchTuneUpdateInfo } from '../utils/fetchUtils/fetchTuneData';

export const LastTuneUpdateDisplay = ({ showLastTuneUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(() => {
    if (showLastTuneUpdate) {
      fetchAndSetLastUpdateTime();
    }
  }, [showLastTuneUpdate]);

  const fetchAndSetLastUpdateTime = async () => {
    setLoading(true);
    try {
      const { lastUpdateTime } = await fetchTuneUpdateInfo();
      setLastUpdate(lastUpdateTime);
    } catch (error) {
      console.error('Error fetching last update time:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!showLastTuneUpdate) {
    return null;
  }

  return (
    <>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Typography variant="body2" color="textSecondary">
          Last Tune Update: {lastUpdate ? formatToUtc(lastUpdate) : 'No data available'}
        </Typography>
      )}
    </>
  );
};
