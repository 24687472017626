import React from 'react';
import { Box, Typography, Button as MuiButton } from '@mui/material';

const FileUpload = ({ file, setFile, error, setError }) => {
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError('');
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
        <MuiButton
          component="label"
          color="primary"
          variant="outlined"
          size="small"
          sx={{ minWidth: '150px', padding: '7px', marginRight: '10px' }}
        >
          Upload File
          <input type="file" hidden onChange={handleFileChange} accept=".csv" />
        </MuiButton>
        {file && <Typography variant="body2">{file.name}</Typography>}
      </Box>
      {!error && (
        <Typography sx={{ margin: '10px 0', fontSize: '12px' }}>
          .csv files under 10,000 rows are allowed.<br />
          .csv should not include header, only hashed values from row 1.
        </Typography>
      )}
      {error && (
        <Typography color="error" sx={{ margin: '10px 0', fontSize: '12px' }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default FileUpload;
