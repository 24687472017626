import AddIcon from '@mui/icons-material/Add'

export const versionNumber = 'v4.0.0'

export const Brand = {
  integrationTypes: {
    magento: 'Magento',
    wp: 'WordPress',
    custom: 'Custom Postback',
    shopify: 'Shopify',
    bigcommerce: 'BigCommerce',
    sdk: 'SDK'
  },
  conflictTypes: ['medium', 'hard'],
  integrations: ['shopify.attributes',
    'shopify.attributes.carthook',
    'shopify.thankyou.page',
    'shopify.note',
    'bigcommerce.thankyou.page',
  ],
  possibleIframeTage: ['pixel'],
  pluginStatus: ['activate', 'deactivate'],
  isAllowTracking: ['true', 'false'],
  defaultOffColumns: [
    'connected_brand_id',
    'connect_brands_customers',
    'domain',
    'site_key',
    'site_secret',
    'plugin_site_secret_match',
    'calc_tax',
    'calc_shipping',
    'plugin_info',
    'multiple_by',
    'integration_func',
    'has_iframe_tags',
    'conflict_type',
    'conflict_systems_ids',
    'unique_conflict_cookies',
    'additional_domain_tracking',
  ],
}

export const BlockEventSettings = {
  types: ['block', 'unblock']
}

export const PreApprovalSettings = {
  decisionResult: [
    'Manual',
    'Default',
    'Paid Advertiser',
    'Paid CPA',
  ],
  decisionFallbackResult: [
    'Manual',
    'Default',
    'Paid Advertiser',
    'Paid CPA',
  ],
  preApprovalType: ['Semi','Full','Null']
}

export const Goals = {
  eventType: [
    'cart_add',
    'order_checkout',
    'order_processing',
    'order_paid',
    'order_refund',
    'order_processing_advertisers',
    'order_processing_cpa'
  ]
}

export const PostbackSettings = {
  postbackSub2: ['customer.email','customer.hashed_email'],
  postbackSub3: ['track_event.params.order_number'],
  postbackSub4: ['customer.phone','customer.hashed_phone'],
  postbackSub5: ['track_transaction.affilate_id'],
}

export const whiteSpaceNoWrap = {
  whiteSpace: 'nowrap'
}

export const searchInputWidth = '500px'

export const roles = {
  0: 'Admin',
  1: 'Brands Manager',
  2: 'Publisher Manager',
  3: 'Media Buyer',
  4: 'Brand Manager VA'
}

export const cronNames = [
  'process_track_views',
  'process_track_events',
  'process_empty_vid_track_events',
  'convert_track_events',
  'convert_empty_vid_track_events',
  'send_track_events',
  'check_brands',
  'pull_wordpress_events',
  'send_emails',
  'fix_data',
  'check_customer_data',
  'remove_payments_from_params',
  'remove_child_events',
  'update_parents_created_by_client_tracking',
  'transfer_data_into_elastic',
  'process_es_failed_data',
  'refresh_tune_entities',
]

export const elasticCronNames = [
  'Regular',
  'Failed',
  'Custom',
  'DataChecker',
  'DataRestructure',
  'DataSync',
  'Notifications',
  'Delayed'
]

export const elasticCronStatuses = [
  'Done',
  'In progress',
  'Pending',
  'Stopped'
]

export const elasticServiceNames = [
  { id: 'eventService', name: 'Event Service' },
  { id: 'viewsService', name: 'Views Service' },
  { id: 'transactionService', name: 'Transaction Service' },
  { id: 'vidService', name: 'Vid Service' },
  { id: 'visitService', name: 'Visit Service' },
  { id: 'notificationService', name: 'Notification Service' },
]

export const preApprovalSettingsCheckers = [
  'All sales',
  'Returning by TID',
]

export const preApprovalSettingsStatuses = [
  'Activated',
  'Deactivated',
]

export const preApprovalSettingsCommission = [
  'CPA',
  'Hybrid',
]

export const orderApprovalDecisions = [
  'Manual',
  'Default',
  'Paid Advertiser',
  'Paid CPA',
]

export const aggregatedTablesPaths = {
  'pre-approval-settings': {
    'affiliate_id': 'pre-approval-settings-aggregated-by-affiliate',
    'offer_id': 'pre-approval-settings-aggregated-by-offer',
  },
  'block-hashed-emails': {
    'brand_id': 'block-hashed-emails-aggregated',
  },
  'block-event-settings': {
    'brand_id': 'block-event-settings-aggregated',
  },
  'order-approval': {
    'brand_id': 'order-approval-aggregated-by-brand',
    'affilate_id': 'order-approval-aggregated-by-affiliate',
  },
  'notification-emails': {
    'brand_id': 'notification-emails-aggregated',
  },
  'goals': {
    'offer_id': 'goals-aggregated',
  },
  'notifications': {
    'brand_id': 'notifications-aggregated',
  },
};

export const tableMenuItems = {
  'pre-approval-settings': {
    defaultValue: '',
    options: [
      { value: '', label: 'None' },
      { value: 'affiliate_id', label: 'Affiliate' },
      { value: 'offer_id', label: 'Offer' },
    ],
  },
  'block-hashed-emails': {
    defaultValue: 'brand_id',
    options: [
      { value: 'block_hashed_emails_list', label: 'None' },
      { value: 'brand_id', label: 'Brand' },
    ],
  },
  'block-event-settings': {
    defaultValue: 'brand_id',
    options: [
      { value: 'block_event_settings_list', label: 'None' },
      { value: 'brand_id', label: 'Brand' },
    ],
  },
  'order-approval': {
    defaultValue: 'brand_id',
    options: [
      { value: 'order_approval_list', label: 'None' },
      { value: 'brand_id', label: 'Brand' },
      { value: 'affilate_id', label: 'Affiliate' },
    ],
  },
  'notification-emails': {
    defaultValue: 'brand_id',
    options: [
      { value: 'notification_emails_list', label: 'None' },
      { value: 'brand_id', label: 'Brand' },
    ],
  },
  'goals': {
    defaultValue: 'offer_id',
    options: [
      { value: 'goals_list', label: 'None' },
      { value: 'offer_id', label: 'Offer' },
    ]
  },
  'notifications': {
    defaultValue: 'brand_id',
    options: [
      { value: 'notifications_list', label: 'None' },
      { value: 'brand_id', label: 'Brand' },
    ],
  },
};

export const buttonProps = {
  create: {
    label: 'Create',
    color: 'primary',
    buttonStyle: { paddingBottom: '2px' },
    variant: 'text',
    startIcon: <AddIcon />
  },
  back: {
    label: 'Back',
    color: 'secondary',
    buttonStyle: { width: '70px', margin: '20px 0 10px 0' },
    variant: 'contained',
    startIcon: null
  }
};

export const copiedFieldsToRemove = {
  'aff-priorities': ['update_time', 'create_time'],
  'affiliate-api-settings': ['update_time', 'create_time'],
  'block-event-settings': ['update_time', 'create_time'],
  'brand': ['update_time', 'check_time', 'create_time'],
  'brand-iframe-tags': ['update_time', 'create_time'],
  'conflict-settings': ['update_time', 'create_time'],
  'coupon-settings': ['update_time', 'create_time'],
  'elastic-cron-settings': ['update_time', 'create_time'],
  'goals': ['update_time', 'create_time'],
  'notification-emails': ['update_time', 'create_time'],
  'postback-settings': ['update_time', 'create_time'],
  'pre-approval-settings': ['update_time', 'create_time'],
};
