import { AppBar, ToggleThemeButton } from 'react-admin'
import { Box, Typography } from '@mui/material'
import { versionNumber } from '../config/constants'
import { Link } from 'react-router-dom'
import './styles/fonts.css'
import { darkTheme, lightTheme } from './themes'


export const MainAppBar = (props) => {
   return (
      <AppBar {...props} elevation={1} color="inherit">
         <Typography
            variant="h6"
            color="inherit"
            sx={{
               flex: 1,
               textOverflow: 'ellipsis',
               whiteSpace: 'nowrap',
               overflow: 'hidden',
            }}
            id="react-admin-title"
         />
         <img src="static/header_icon_120x75.png" style={{width: '50px'}}/>
         <span className="app-bar-header-title">AffsMedia</span>
         <i style={{color: '#8D1A88', fontSize: '25px', marginBottom: '5px'}}>TrackCB</i>
         <Box component="span" sx={{flex: 1}}/>
         <div className="row-fluid">
            <div className="text-center"><Link to="/version-history">{versionNumber}</Link></div>
         </div>
         <ToggleThemeButton
            lightTheme={lightTheme}
            darkTheme={darkTheme}
         />
      </AppBar>
   )
}