import * as React from 'react'
import {
   List,
   Datagrid,
   TextField,
   TextInput,
   FunctionField,
} from 'react-admin'
import { RowsPerPage, BulkActionCopy } from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'

const filters = [
   <TextInput source="search" label="Shop, State, Expires" alwaysOn className="search-input-width"/>,
   <TextInput source="isOnline" label="Is online" />,
   <TextInput source="onlineAccessInfo" />,
]

export const ShopifySessionsList = props => {
   const rowClassName = useRowStyle()

   return (
      <List {...props}
            pagination={<RowsPerPage/>}
            filters={filters}
            className="list-admin-wrapper"
            actions={<ToolBarRowStyleToggle/>}
      >
         <Datagrid rowClick="edit" className={rowClassName} bulkActionButtons={<BulkActionCopy/>}>
            <TextField source="id" />
            <TextField source="shop" />
            <TextField source="state" />
            <TextField source="isOnline" />
            <TextField source="scope" />
            <TextField source="expires" />
            <TextField source="onlineAccessInfo" />
            <FunctionField
               source="accessToken"
               render={record => 
                  <div style={{ 
                     width: '200px',
                     wordWrap: 'break-word'
                  }}>
                     {record.accessToken}
                  </div>
                  }
               />
         </Datagrid>
      </List>
   )
}
