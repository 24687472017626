import { TextInput } from 'react-admin';
import { Card, Typography, Switch, FormControlLabel } from '@mui/material';
import { CronStatusLabel } from './CronStatusLabel';
import { ApiTrackingStatusLabel } from './ApiTrackingStatusLabel';
import { isDomainTrackingDisabled } from '../../../utils/brandUtils';

export const MainDomainTrackingCard = ({
  handleCronStatusChange,
  pluginInfo,
  type,
  initCronStatus,
}) => {
  return (
    <Card className="p-4" sx={{ width: '600px' }}>
      <Typography variant="h6">Main domain API tracking</Typography>
      <div className="row pt-3 px-2">
        <TextInput
          source="home_url"
          label="Main Domain"
          fullWidth
          disabled={true}
        />
        {isDomainTrackingDisabled(type)
          ? <ApiTrackingStatusLabel pluginInfo={pluginInfo} type={type} />
          : <>
              <CronStatusLabel cronStatus={typeof initCronStatus === 'undefined' ? 'error' : pluginInfo.cron_status} />
              <FormControlLabel
                control={
                  <Switch
                    checked={pluginInfo.cron_status === 'error'}
                    onChange={handleCronStatusChange}
                  />
                }
                label="Rest API"
              />
          </>
        }
      </div>
    </Card>
  );
};
