import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';
import {
  RowsPerPage,
  ContextParser,
  DateInOneRow,
  BulkActionCopy,
} from '../../components';
import { useRowStyle } from '../../hooks/useRowStyle';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { useLocalStorageEffect } from '../../hooks/useLocalStorageEffect';
import { useRedirectFromParams } from '../../hooks/useRedirectFromParams';
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle';
import { copyLocalStorageValues } from '../../utils/helpers';
import { getNotificationEmailsAggregatedFilters } from '../../utils/filters/notificationEmailsFilters';

export const NotificationsEmailsAggregatedList = (props) => {
  const rowClassName = useRowStyle();
  const { total: brandsCount } = useFetchEntities('brand');
  const navigate = useNavigate();
 
  const handleRowClick = (id, basePath, record) => {
    const queryParams = copyLocalStorageValues({
      sourceKey: 'RaStore.notification-emails-aggregated.listParams',
      targetKey: 'RaStore.notification-emails.listParams',
      entityId: JSON.stringify({context: { id: record.context.brand.id }}),
    });

    navigate(`/notification-emails${queryParams}`);
  };

  useEffect(() => {
    localStorage.setItem('aggregate', 'brand_id');
  }, []);

  useLocalStorageEffect('RaStore.notification-emails-aggregated.listParams', 'context');
  useRedirectFromParams('context', 'notification-emails');

  return (
    <List
      {...props}
      pagination={<RowsPerPage />}
      filters={getNotificationEmailsAggregatedFilters(brandsCount)}
      className="list-admin-wrapper"
      actions={<ToolBarRowStyleToggle
        createButtonUrl='/notification-emails/create'
        tableName='notification-emails'
      />}
      title="Notification-emails-aggregated"
    >
      <Datagrid
        rowClick={handleRowClick}
        className={rowClassName}
        bulkActionButtons={<BulkActionCopy showCopyButton={false} showDeleteButton={false} />}
      >
        <TextField source="count" />
        <TextField label="Sent success" />
        <TextField label="Plugin status" />
        <TextField label="Subject" />
        <TextField label="Template" />
        <TextField label="Brand Domain" />
        <ContextParser source="context" field="id" label="Brand" />
        <TextField label="Integration type" />
        <DateInOneRow
          source="update_time"
          label="Update time"
          showTime={true}
        />
      </Datagrid>
    </List>
  );
};
