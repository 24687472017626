import { Toolbar, SaveButton } from 'react-admin'


export const AlwaysOnSaveButton = props => {
   const handleSubmitWithRedirect = () => {
      const message = `This setting is very sensitive,
                        Are you sure you want to change it?`

      if (!window.confirm(message)) {
         return
      } else {
         props.handleSubmitWithRedirect()
      }
   }
   return (
      <Toolbar {...props}
               handleSubmitWithRedirect={handleSubmitWithRedirect}
               style={{display: 'flex', flex: '1 1 0%', justifyContent: 'space-between'}}
               alwaysEnableSaveButton={true}>
         <SaveButton/>
      </Toolbar>
   )
}