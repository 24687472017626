import { TextInput } from 'react-admin';
import { Grid } from '@mui/material';
import { offerMetadataFields } from '../../../config/constants/tuneManagementSystem/offerMetadata';

const OfferMetadata = () => {
  return (
    <Grid container spacing={1}>
      {offerMetadataFields.map(({ source, label, format, disabled, multiline }, index) => (
        <Grid item xs={12} md={6} key={index}>
          <TextInput
            className="search-input-md"
            source={source}
            label={label}
            format={format}
            disabled={disabled}
            multiline={multiline}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default OfferMetadata;
