import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { copyLocalStorageValues, getFilterFromSearchParams } from '../utils/helpers';

export const useRedirectIfNoFilter = ({ filterKey, redirectLink, sourceKey, targetKey, shouldRedirect }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const filter = getFilterFromSearchParams(location.search);
        if (!filter || !shouldRedirect) return;
        
        const hasFilterKey = filter.hasOwnProperty(filterKey);
        if (!filter[filterKey]) {
            if (hasFilterKey) {
                localStorage.setItem(sourceKey, {});
                const newPathname = location.pathname;
                navigate(newPathname, { replace: true });
            } else {
                const queryParams = copyLocalStorageValues({ sourceKey, targetKey });
                navigate(`/${redirectLink}${queryParams}`, { replace: true });
            }
        }
    }, [location.search, navigate, filterKey, redirectLink, sourceKey, targetKey, shouldRedirect]);
};
