import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { BrowserRouter } from 'react-router-dom'

const rootElement = document.getElementById('root')
const scaleOfScreen = window.devicePixelRatio

let className;

if (scaleOfScreen > 1 && scaleOfScreen < 1.5) {
    className = 'zoom-md';
} else if (scaleOfScreen >= 1.5) {
    className = 'zoom-sm';
} else {
    className = 'zoom-lg';
}

const root = ReactDOM.createRoot(rootElement)

root.render(
    <BrowserRouter>
        <div className="zoom-container">
            <div className={className}>
                <React.StrictMode>
                    <App/>
                </React.StrictMode>
            </div>
        </div>
    </BrowserRouter>
)
