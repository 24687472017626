import * as React from 'react'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { SubMenu } from './SubMenu'
import {
   Settings,
   Dashboard,
   List,
   SettingsApplications,
   Apps,
   BrandingWatermark,
   Flag,
   Email,
   Schedule,
   AttachMoney,
   Announcement,
   AccountBox,
   Tune,
} from '@mui/icons-material'

import {
   MenuItemLink,
   useSidebarState,
} from 'react-admin'
import { isAllowedResource } from '../config/auth-provider'


export const MainMenu = ({dense = false}) => {
   const [state, setState] = useState({
      menuBrandSettings: false,
      menuEventSettings: false,
      menuTrackingSettings: false,
      menuOthers: false,
      menuTuneManagement: false
   })
   const [open] = useSidebarState()

   const role = JSON.parse(localStorage.getItem('permissions'))

   const handleToggle = (menu) => {
      setState(state => ({...state, [menu]: !state[menu]}))
   }

   const aggregateValue = localStorage.getItem('aggregate') || '';

   return (
      <Box
         sx={{
            width: open ? 280 : 50,
            marginTop: 1,
            marginBottom: 1,
            transition: theme =>
               theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
               }),
         }}
      >
         <MenuItemLink
            to="/"
            primaryText={'Dashboard'}
            sidebarIsOpen={open}
            leftIcon={<Dashboard/>}
            dense={dense}
         />
         {
            isAllowedResource(role, 'brand-settings') &&
            <SubMenu
               handleToggle={() => handleToggle('menuBrandSettings')}
               isOpen={state.menuBrandSettings}
               name="Brand settings"
               dense={dense}
               icon={<Apps/>}
            >
               {[
                  isAllowedResource(role, 'brand') &&
                  <MenuItemLink
                     to="/brand"
                     state={{_scrollToTop: true}}
                     primaryText="Brands"
                     dense={dense}
                     leftIcon={<BrandingWatermark/>}
                  />,

                  isAllowedResource(role, 'brand-iframe-tags') &&
                  <MenuItemLink
                     to="/brand-iframe-tags"
                     state={{_scrollToTop: true}}
                     primaryText="Brands Tags"
                     dense={dense}
                     leftIcon={<List/>}
                  />,

                  isAllowedResource(role, 'goals-aggregated') &&
                  <MenuItemLink
                     to="/goals-aggregated"
                     state={{_scrollToTop: true}}
                     primaryText="Goal"
                     dense={dense}
                     leftIcon={<Flag/>}
                  />,

                  isAllowedResource(role, 'notification-emails-aggregated') &&
                  <MenuItemLink
                     to="/notification-emails-aggregated"
                     state={{_scrollToTop: true}}
                     primaryText="Notification emails"
                     dense={dense}
                     leftIcon={<Email/>}
                  />,

                  isAllowedResource(role, 'conflict-settings') &&
                  <MenuItemLink
                     to="/conflict-settings"
                     state={{_scrollToTop: true}}
                     primaryText="Conflict Systems"
                     dense={dense}
                     leftIcon={<List/>}
                  />
               ]}
            </SubMenu>
         }
         {
            isAllowedResource(role, 'event-settings') &&
            <SubMenu
               handleToggle={() => handleToggle('menuEventSettings')}
               isOpen={state.menuEventSettings}
               name="Event settings"
               dense={dense}
               icon={<Settings/>}
            >
               {[
                  isAllowedResource(role, 'order-approval-aggregated-by-brand') &&
                  <MenuItemLink
                     to={aggregateValue === 'affilate_id'
                        ? "/order-approval-aggregated-by-affiliate"
                        : "/order-approval-aggregated-by-brand"
                     }
                     state={{_scrollToTop: true}}
                     primaryText="Order Approval"
                     dense={dense}
                     leftIcon={<List/>}
                  />,

                  isAllowedResource(role, 'pre-approval-settings') &&
                  <MenuItemLink
                     to="/pre-approval-settings"
                     state={{_scrollToTop: true}}
                     primaryText="Pre-approval Settings"
                     dense={dense}
                     leftIcon={<List/>}
                  />,

                  isAllowedResource(role, 'postback-settings') &&
                  <MenuItemLink
                     to="/postback-settings"
                     state={{_scrollToTop: true}}
                     primaryText="Tune Postback"
                     dense={dense}
                     leftIcon={<List/>}
                  />,

                  isAllowedResource(role, 'block-event-settings-aggregated') &&
                  <MenuItemLink
                     to="/block-event-settings-aggregated"
                     state={{_scrollToTop: true}}
                     primaryText="Block Events"
                     dense={dense}
                     leftIcon={<List/>}
                  />,

                  isAllowedResource(role, 'block-hashed-emails-aggregated') &&
                  <MenuItemLink
                     to="/block-hashed-emails-aggregated"
                     state={{_scrollToTop: true}}
                     primaryText="Block Hashed Emails"
                     dense={dense}
                     leftIcon={<List/>}
                  />,

                  isAllowedResource(role, 'coupon-settings') &&
                  <MenuItemLink
                     to="/coupon-settings"
                     state={{_scrollToTop: true}}
                     primaryText="Coupon Events"
                     dense={dense}
                     leftIcon={<List/>}
                  />
               ]}
            </SubMenu>
         }
         {
            isAllowedResource(role, 'tracking-settings') &&
            <SubMenu
               handleToggle={() => handleToggle('menuTrackingSettings')}
               isOpen={state.menuTrackingSettings}
               name="Tracking settings"
               dense={dense}
               icon={<SettingsApplications/>}
            >
               {[
                  isAllowedResource(role, 'aff-priorities') &&
                  <MenuItemLink
                     to="/aff-priorities"
                     state={{_scrollToTop: true}}
                     primaryText="Affiliate Priority"
                     dense={dense}
                     leftIcon={<List/>}
                  />
               ]}
               {[
                  isAllowedResource(role, 'shopify-sessions') &&
                  <MenuItemLink
                     to="/shopify-sessions"
                     state={{_scrollToTop: true}}
                     primaryText="Shopify Sessions"
                     dense={dense}
                     leftIcon={<List/>}
                  />
               ]}
            </SubMenu>
         }
         {
            isAllowedResource(role, 'tune-management') &&
            <SubMenu
               handleToggle={() => handleToggle('menuTuneManagement')}
               isOpen={state.menuTuneManagement}
               name="Tune Mgmt"
               dense={dense}
               icon={<Settings/>}
            >
               {[
                  isAllowedResource(role, 'offers') &&
                     <MenuItemLink
                        to="/offers"
                        state={{_scrollToTop: true}}
                        primaryText="Offers"
                        dense={dense}
                        leftIcon={<List/>}
                     />,
               ]}
            </SubMenu>
         }
         {
            isAllowedResource(role, 'others') &&
            <SubMenu
               handleToggle={() => handleToggle('menuOthers')}
               isOpen={state.menuOthers}
               name="Others"
               dense={dense}
               icon={<SettingsApplications/>}
            >
               {[
                  isAllowedResource(role, 'affiliate-api-settings') &&
                  <MenuItemLink
                     to="/affiliate-api-settings"
                     state={{_scrollToTop: true}}
                     primaryText="Affiliate API Settings"
                     dense={dense}
                     leftIcon={<Tune />}
                  />,

                  isAllowedResource(role, 'cron-settings') &&
                  <MenuItemLink
                     to="/cron-settings"
                     state={{_scrollToTop: true}}
                     primaryText="Cron settings"
                     dense={dense}
                     leftIcon={<Schedule/>}
                  />,

                  isAllowedResource(role, 'elastic-cron-settings') &&
                  <MenuItemLink
                     to="/elastic-cron-settings"
                     state={{_scrollToTop: true}}
                     primaryText="Elastic Cron settings"
                     dense={dense}
                     leftIcon={<Schedule/>}
                  />,

                  isAllowedResource(role, 'currency') &&
                  <MenuItemLink
                     to="/currency"
                     state={{_scrollToTop: true}}
                     primaryText="Currency"
                     dense={dense}
                     leftIcon={<AttachMoney/>}
                  />,

                  // isAllowedResource(role, 'notifications-aggregated') &&
                  // <MenuItemLink
                  //    to="/notifications-aggregated"
                  //    state={{_scrollToTop: true}}
                  //    primaryText="Notifications"
                  //    dense={dense}
                  //    leftIcon={<Announcement/>}
                  // />,
                  isAllowedResource(role, 'notifications') &&
                  <MenuItemLink
                     to="/notifications"
                     state={{_scrollToTop: true}}
                     primaryText="Notifications"
                     dense={dense}
                     leftIcon={<Announcement/>}
                  />,

                  isAllowedResource(role, 'users') &&
                  <MenuItemLink
                     to="/users"
                     state={{_scrollToTop: true}}
                     primaryText="Users"
                     dense={dense}
                     leftIcon={<AccountBox/>}
                  />
               ]}
            </SubMenu>
         }
      </Box>
   )
}