import { Box, Card, Typography } from '@mui/material'
import { DateInOneRow } from '../components'

export const AsideHistory = ({isCheckTime}) => {
  return (
    <Card style={{maxHeight: isCheckTime ? '200px' : '150px', marginLeft: '20px'}}>
      <Box margin={2} display="flex" flexDirection="column">
        <Typography variant="h6">
          History
        </Typography>
        <Box display="flex">
          <Box display="flex" flexDirection="column">
            <Typography variant="overline">
              Create time
            </Typography>
            <DateInOneRow source="create_time" label="Create time" showTime={true}/>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft={5}>
            <Typography variant="overline">
              Update time
            </Typography>
            <DateInOneRow source="update_time" label="Update time" showTime={true}/>
          </Box>
        </Box>
        {isCheckTime &&
          <Box display="flex" flexDirection="column" marginY={2}>
            <Typography variant="overline">
              Check time
            </Typography>
            <DateInOneRow source="check_time" label="Check time" showTime={true}/>
          </Box>
        }
      </Box>
    </Card>
  )
}