import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  number,
  required,
} from 'react-admin'
import { AsideHistory, GoBackButton, CustomEditToolbar } from '../../components'

export const ShopifySessionsEdit = props => (
  <>
    <GoBackButton path="/shopify-sessions"/>
    <Edit {...props} aside={<AsideHistory/>}>
      <SimpleForm toolbar={<CustomEditToolbar />}>
        <TextInput source="id" className="w-50" validate={[required()]} />
        <TextInput source="shop" className="w-50" validate={[required()]} />
        <TextInput source="state" className="w-50" validate={[required()]} />
        <TextInput source="isOnline" validate={[number(), required()]} />
        <TextInput source="scope" />
        <TextInput source="expires" validate={[number()]} />
        <TextInput source="onlineAccessInfo" />
        <TextInput source="accessToken" className="w-50" />
      </SimpleForm>
    </Edit>
  </>
)
