import { Button } from '@mui/material';
import { apiUrl } from '../config/http-client';

export const DownloadButton = ({ downloadEndpoint, label = 'Download' }) => {
  const handleDownload = () => {
    window.location.href = `${apiUrl}/${downloadEndpoint}`;
  };

  return (
    <Button
      style={{ minWidth: '226px', fontSize: '12px' }}
      variant="contained"
      color="secondary"
      onClick={handleDownload}
    >
      {label}
    </Button>
  );
};
